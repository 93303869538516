import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { urls } from 'app-constants';
import Modal from 'components/common/Modal';
import ErrorBoundary from 'components/common/ErrorBoundary';
import Message from 'components/common/Message';
import SourcePicker from './SourcePicker';
import FileUpload from './FileUpload';
import Podcast from './Podcast';
import Url from './Url';
import Vimeo from './Vimeo';
import YouTube from './YouTube';

const MediaImportModal = ({
  title = 'Add Media',
  isOpen = false,
  workspaceAdmin = false,
  instanceId,
  onRequestClose = () => {},
  onImportComplete = () => {},
}) => {
  const [importSource, setImportSource] = useState(null);

  const handleRequestClose = () => {
    setImportSource(null);
    onRequestClose();
  };

  const handleImportComplete = () => {
    setImportSource(null);
    onImportComplete();
  };

  const sharedProps = {
    workspaceAdmin,
    instanceId,
    onComplete: handleImportComplete,
  };

  const sourceComponent = importSource ? {
    upload: <FileUpload {...sharedProps} />,
    url: <Url endpoint={urls.mediaImportUrl} {...sharedProps} />,
    vimeo: <Vimeo {...sharedProps} />,
    youtube: <YouTube {...sharedProps} />,
    podcast: <Podcast {...sharedProps} />,
  }[importSource] : <SourcePicker onSelect={setImportSource} />;

  return (
    <Modal
      title={title}
      style={{ content: { width: 800 } }}
      isOpen={isOpen}
      onRequestClose={handleRequestClose}
    >
      {!!instanceId && (
        <div className="mb-4">
          <Message
            type="warning"
            clearable={false}
            text="Note: this action will overwrite the current media source and cannot be undone. If you have unsaved changes, save before continuing."
          />
        </div>
      )}

      <ErrorBoundary>
        {sourceComponent}
      </ErrorBoundary>
    </Modal>
  );
};

MediaImportModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  workspaceAdmin: PropTypes.bool,
  instanceId: PropTypes.string,
  onRequestClose: PropTypes.func,
  onImportComplete: PropTypes.func,
};

export default MediaImportModal;
