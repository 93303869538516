import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import parseISO from 'date-fns/parseISO';
import { formatFlexDate, combineDateParts, splitDateParts } from 'utils';
import { MEDIA_SOURCE_URL, MEDIA_SOURCE_LIBRARY } from './';
import { ErrorList } from 'components/common/inlines';
import Icon from 'components/common/Icon';
import QuillEditor from 'components/common/QuillEditor';
import DateTimeWidget from 'components/common/DateTimeWidget';
import AttachedItemPreview from 'components/common/AttachedItemPreview';
import AddLibraryMedia from 'components/common/AddLibraryMedia';
import AddUrlMedia from './AddUrlMedia';
import ExternalMediaPreview from './ExternalMediaPreview';
import itemFormDefaults from './itemFormDefaults';


class Form extends Component {
  constructor (props) {
    super(props);
    this.defaultState = {
      itemId: null,
      lastInsertDate: null,
      fields: { ...itemFormDefaults },
      libraryMedia: {
        description: '',
        credit: '',
      },
    };
    this.state = {
      ...this.defaultState,
    };

    this.numericFields = ['year', 'month', 'day'];
    this.requiredFields = ['year'];

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleRichTextInputChange = this.handleRichTextInputChange.bind(this);
    this.initFormValues = this.initFormValues.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormChange = debounce(this.handleFormChange, 500);
    this.handleRemoveMedia = this.handleRemoveMedia.bind(this);
    this.handleAddUrlMedia = this.handleAddUrlMedia.bind(this);
    this.handleAddLibraryMedia = this.handleAddLibraryMedia.bind(this);
    this.handleLibraryMediaLoad = this.handleLibraryMediaLoad.bind(this);
    this.handleCaptureDateShortcutClick = this.handleCaptureDateShortcutClick.bind(this);
    this.getItemObject = this.getItemObject.bind(this);
    this.errorClass = this.errorClass.bind(this);
  }

  componentDidMount () {
    this.initFormValues();
  }

  componentDidUpdate (prevProps, prevState) {
    const { item } = this.props;
    const { fields } = this.state;

    if (!isEqual(item, prevProps.item)) {
      this.initFormValues();

      // Reset form scroll position when a different item is selected
      this.containerRef.scrollTop = 0;
    }

    if (fields.layout !== 'quote' && fields.quoteAttribution) {
      this.setState({
        fields: {
          ...fields,
          quoteAttribution: '',
        },
      });
    }
  }

  handleDateChange (dateFields) {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        ...dateFields,
      },
    }, this.handleFormChange);
  }

  handleInputChange (e) {
    const { fields } = this.state;
    let { name, value, type, checked } = e.target;
    if (value && this.numericFields.includes(name)) {
      value = parseInt(value, 10);
    } else if (type === 'checkbox') {
      value = checked;
    }
    this.setState({
      fields: {
        ...fields,
        [name]: value,
      },
    }, this.handleFormChange);
  }

  handleRichTextInputChange (fieldName, value) {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        [fieldName]: value,
      },
    }, this.handleFormChange);
  }

  handleFormChange () {
    const { onChange, item } = this.props;
    const itemObj = this.getItemObject();

    if (item) {
      // Only call this if we are editing an existing item, not a blank one.
      onChange({ ...itemObj });
    }
  }

  handleRemoveMedia () {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        mediaSource: MEDIA_SOURCE_URL,
        mediaUrl: '',
        media: '',
        mediaDescription: '',
        mediaCredit: '',
      },
      libraryMedia: {},
    }, this.handleFormChange);
  }

  handleAddUrlMedia (url) {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        mediaSource: MEDIA_SOURCE_URL,
        mediaUrl: url,
      },
    }, this.handleFormChange);
  }

  handleAddLibraryMedia (mediaId) {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        mediaSource: MEDIA_SOURCE_LIBRARY,
        media: mediaId,
        mediaDescriptionInherit: true,
        mediaCreditInherit: true,
      },
    }, this.handleFormChange);
  }

  handleLibraryMediaLoad (mediaObj) {
    const { longDescription: description, credit, placename, captured, captureDateComponents } = mediaObj;
    this.setState({
      libraryMedia: { description, credit, placename, captured, captureDateComponents },
    });
  }

  handleCaptureDateShortcutClick (evt) {
    const { libraryMedia: { captureDateComponents } } = this.state;
    evt.preventDefault();
    const { year, month, day, time, precision } = captureDateComponents;
    const parts = {
      year,
      month: null,
      day: null,
      time: '',
    };

    switch (precision) {
      case 'time':
        parts.time = time;
        // fall through
      case 'day':
        parts.day = day;
        // fall through
      case 'month':
        parts.month = month;
    }

    this.handleDateChange(parts);
  }

  getItemObject () {
    // Convert empty strings to null
    return Object.keys(this.state.fields).reduce((result, key) => {
      result[key] = this.state.fields[key] === '' ? null : this.state.fields[key];
      return result;
    }, {});
  }

  initFormValues () {
    const { item } = this.props;

    if (item) {
      const newFields = Object.keys(item).reduce((result, key) => {
        if (this.defaultState.fields.hasOwnProperty(key)) {
          // Keep explicit false for checkbox fields, but convert undefined or null to empty strings
          result[key] = (item[key] || item[key] === false) ? item[key] : '';
        }
        return result;
      }, {});
      this.setState({ fields: newFields, itemId: item.itemId });
    } else {
      this.setState({ fields: this.defaultState.fields, itemId: null });
    }
  }

  errorClass (fieldName) {
    const { item } = this.props;
    return item && item.errors[fieldName] ? 'has-error' : '';
  }

  renderMediaFields () {
    const { libraryMedia } = this.state;
    const {
      mediaSource, media, mediaUrl, mediaDescription, mediaCredit,
      mediaDescriptionInherit, mediaCreditInherit,
    } = this.state.fields;

    if (mediaSource === MEDIA_SOURCE_URL && mediaUrl) {
      return (
        <>
          <div className="mb-5">
            <ExternalMediaPreview mediaUrl={mediaUrl} onDeleteClick={this.handleRemoveMedia} />
          </div>

          <div className={`form-group mb-5 ${this.errorClass('mediaDescription')}`}>
            <input type="hidden" name="mediaDescriptionInherit" value={false} />
            <label className="block-label">Media Description</label>
            <QuillEditor
              inputName="mediaDescription"
              value={mediaDescription}
              size="small"
              onChange={value => this.handleRichTextInputChange('mediaDescription', value)}
            />
          </div>

          <div className={`form-group mb-5 ${this.errorClass('mediaCredit')}`}>
            <input type="hidden" name="mediaCreditInherit" value={false} />
            <label className="block-label">Media Credit</label>
            <input name="mediaCredit" type="text" className="form-input" value={mediaCredit} onChange={this.handleInputChange} />
          </div>
        </>
      );
    } else if (mediaSource === MEDIA_SOURCE_LIBRARY && media) {
      return (
        <>
          <div className="mb-5">
            <AttachedItemPreview itemType="media" itemId={media} onLoad={this.handleLibraryMediaLoad} clickableThumbnail>
              <div className="btn-delete tooltip tooltip-left" data-tooltip="Remove Media" onClick={this.handleRemoveMedia}>
                <Icon name="delete" fill />
              </div>
            </AttachedItemPreview>
          </div>

          <div className={`form-group mb-5 ${this.errorClass('mediaDescription')}`}>
            <div style={{ float: 'right', height: 21, display: 'flex', alignItems: 'center' }}>
              <label className="form-checkbox checkbox-right-sm">
                <input type="checkbox" name="mediaDescriptionInherit" checked={mediaDescriptionInherit} onChange={this.handleInputChange} />
                Inherit from selected media<i className="form-icon" />
              </label>
            </div>
            <label className="block-label">Media Description</label>
            {/* TODO: render HTML in disabled text field */}
            {mediaDescriptionInherit ? (
              <textarea cols="30" rows="3" className="form-input" value={libraryMedia.description} disabled />
            ) : (
              <QuillEditor
                inputName="mediaDescription"
                value={mediaDescription}
                size="small"
                onChange={value => this.handleRichTextInputChange('mediaDescription', value)}
              />
            )}
          </div>

          <div className={`form-group mb-5 ${this.errorClass('mediaCredit')}`}>
            <div style={{ float: 'right', height: 21, display: 'flex', alignItems: 'center' }}>
              <label className="form-checkbox checkbox-right-sm">
                <input type="checkbox" name="mediaCreditInherit" checked={mediaCreditInherit} onChange={this.handleInputChange} />
                Inherit from selected media<i className="form-icon" />
              </label>
            </div>
            <label className="block-label">Media Credit</label>
            {mediaCreditInherit ? (
              <input type="text" className="form-input" value={libraryMedia.credit || ''} disabled />
            ) : (
              <input name="mediaCredit" type="text" className="form-input" value={mediaCredit || ''} onChange={this.handleInputChange} />
            )}
          </div>
        </>
      );
    } else {
      return (
        <div className="mb-5">
          <AddLibraryMedia onChange={this.handleAddLibraryMedia} />
          <AddUrlMedia onChange={this.handleAddUrlMedia} />
        </div>
      );
    }
  }

  render () {
    const { item } = this.props;
    const { lastInsertDate, libraryMedia } = this.state;
    const {
      year, month, day, time, dateDisplay, layout, headline, description, quoteAttribution, mediaSource,
      media, location, locationInherit,
    } = this.state.fields;
    const isQuoteLayout = layout === 'quote';
    const itemDate = combineDateParts({ year, month, day, time });
    const hasErrors = item && Object.keys(item.errors).length > 0;
    const headerText = item ? (item.headline || '[No Headline]') : '[New Slide]';

    let formattedDate = '';
    try {
      formattedDate = formatFlexDate({ year, month, day, time });
    } catch (e) {
      console.error(e);
    }

    return (
      <div className="title-builder-form-container">
        <header className="title-builder-form-header">
          <div>
            <h5><span>Editing:</span> {headerText}</h5>
          </div>
        </header>

        <div className="title-builder-form" ref={node => this.containerRef = node}>
          {hasErrors ? <ErrorList className="mb-5" errors={item.errors} /> : null}

          <div className="d-flex align-items-center" style={{ flexWrap: 'wrap' }}>
            <DateTimeWidget
              year={year || null}
              month={month || null}
              day={day || null}
              time={time}
              yearError={!!item && !!item.errors.year}
              monthError={!!item && !!item.errors.month}
              dayError={!!item && !!item.errors.day}
              timeError={!!item && !!item.errors.time}
              calendarDefaultDate={itemDate || lastInsertDate}
              onChange={this.handleDateChange}
            />

            {!!libraryMedia.captured && (
              <a
                href="#"
                className="d-flex align-items-center td-none"
                onClick={this.handleCaptureDateShortcutClick}
              >
                <Icon name="sync" />
                <span className="text-sm ml-1">Sync to media capture date</span>
              </a>
            )}

            <div style={{ flex: 1 }} />

            <div className={`form-group mb-5 ${this.errorClass('layout')}`}>
              <label className="block-label">Slide Type</label>
              <select name="layout" className="form-select" value={layout} onChange={this.handleInputChange}>
                <option value="">Default</option>
                <option value="quote">Quote</option>
              </select>
            </div>
          </div>

          <hr className="mt-0 mb-3" />

          <div className={`form-group mb-4 ${this.errorClass('dateDisplay')}`}>
            <label className="block-label">Date Display</label>
            <input name="dateDisplay" type="text" className="form-input" placeholder={formattedDate} value={dateDisplay} onChange={this.handleInputChange} />
            <div className="text-hint">Enter custom date display text, or leave blank to use the default, auto-formatted date.</div>
          </div>

          <div className={`form-group mb-5 ${this.errorClass('location')}`}>
            {mediaSource === MEDIA_SOURCE_LIBRARY && !!media && (
              <div style={{ float: 'right', height: 21, display: 'flex', alignItems: 'center' }}>
                <label className="form-checkbox checkbox-right-sm">
                  <input type="checkbox" name="locationInherit" checked={locationInherit} onChange={this.handleInputChange} />
                  Inherit from selected media<i className="form-icon" />
                </label>
              </div>
            )}
            <label className="block-label">Location</label>
            {mediaSource === MEDIA_SOURCE_LIBRARY && !!media && locationInherit ? (
              <input type="text" className="form-input" value={libraryMedia.placename} disabled />
            ) : (
              <input name="location" type="text" className="form-input" value={location} onChange={this.handleInputChange} />
            )}
          </div>

          <div className={`form-group mb-5 ${this.errorClass('headline')}`}>
            <label className="block-label">Headline</label>
            <input name="headline" type="text" className="form-input" value={headline} onChange={this.handleInputChange} />
          </div>

          <div className={`form-group mb-5 ${this.errorClass('description')}`}>
            <label className="block-label">{isQuoteLayout ? 'Quote Text' : 'Description'}</label>
            <QuillEditor
              inputName="description"
              value={description}
              size="small"
              onChange={value => this.handleRichTextInputChange('description', value)}
            />
          </div>

          {isQuoteLayout && (
            <div className={`form-group mb-5 ${this.errorClass('quoteAttribution')}`}>
              <label className="block-label">Quote Attribution</label>
              <input name="quoteAttribution" type="text" className="form-input" value={quoteAttribution} onChange={this.handleInputChange} />
            </div>
          )}

          <hr className="mt-0 mb-3" />

          <div className="mb-2">
            <label className="block-label">Media</label>
          </div>

          {this.renderMediaFields()}
        </div>
      </div>
    );
  }
}

Form.propTypes = {
  item: PropTypes.object,
  onChange: PropTypes.func,
  onSaveNew: PropTypes.func,
};

export default Form;
