import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelize from 'camelize';
import { snakeCase } from 'change-case';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import { usePortal } from 'hooks';
import withCSRF from 'components/common/withCSRF';
import FieldWrapper from 'components/views/CustomTitleBuilder/CustomTitleField/FieldWrapper';
import Icon from 'components/common/Icon';
import LibraryMediaField from 'components/common/LibraryMediaField';
import LoadingOverlay from 'components/common/LoadingOverlay';
import Message from 'components/common/Message';
import QuillEditor from 'components/common/QuillEditor';


const ContributorForm = ({ csrfToken, containerEl, isOpen, contributorId, onSave, onCancel }) => {
  const renderPortal = usePortal(containerEl);

  const fieldDefaults = {
    firstName: '',
    middleName: '',
    lastName: '',
    bioMedia: '',
    bioUrl: '',
    organization: '',
    organizationUrl: '',
    bio: '',
  };

  const [fieldValues, setFieldValues] = useState(fieldDefaults);
  const [formErrors, setFormErrors] = useState({});
  const [formDataFetching, setFormDataFetching] = useState(false);
  const [creditsData, setCreditsData] = useState(null);
  const [contributorPk, setContributorPk] = useState(null);

  const isNew = !contributorId;

  const handleFieldChange = e => {
    let { name, value } = e.target;
    name = name.replace('contributorForm-', '');
    setFieldValues({ ...fieldValues, [name]: value });
  };

  const createChangeHandler = useCallback(fieldName => {
    return value => setFieldValues(oldState => ({ ...oldState, [fieldName]: value }));
  }, [setFieldValues]);

  const handleBioChange = createChangeHandler('bio');
  const handleBioMediaChange = createChangeHandler('bioMedia');

  const handleSubmit = e => {
    e.preventDefault();
    setFormDataFetching(true);

    const formData = new FormData();
    Object.entries(fieldValues).forEach(([key, val]) => {
      formData.append(snakeCase(key), val);
    });

    const url = isNew ? urls.contributorCreate : urlJoin(urls.contributorDetailBase, contributorId, '/');
    const method = isNew ? 'POST' : 'PATCH';

    fetch(url, {
      credentials: 'include',
      method,
      headers: {
        'X-CSRFToken': csrfToken,
      },
      body: formData,
    })
      .then(response => {
        if (response.status === 400) {
          // Form validation errors
          response.json().then(data => {
            setFormErrors(camelize(data));
            setFormDataFetching(false);
          });
        } else if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          response.json().then(data => {
            updateFieldValues(camelize(data));
            onSave(camelize(data));
            setFormDataFetching(false);
          });
        }
        return response;
      })
      .catch(err => {
        setFormDataFetching(false);
        console.error(err);
      });
  };

  const handleFormClose = () => onCancel();

  const handleKeyUp = e => {
    if (e.key === 'Escape') {
      e.preventDefault();
      onCancel();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keyup', handleKeyUp);
    } else {
      document.removeEventListener('keyup', handleKeyUp);
    }
    return () => document.removeEventListener('keyup', handleKeyUp);
  }, [isOpen]);

  const loadFormData = () => {
    setFormDataFetching(true);
    const url = urlJoin(urls.contributorDetailBase, contributorId, '/');
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(data => {
        data = camelize(data);
        updateFieldValues(data);
        setCreditsData(data.credits);
        setContributorPk(data.id);
        setFormDataFetching(false);
      })
      .catch(err => {
        setFormDataFetching(false);
        console.error(err);
      });
  };

  const clearFormData = () => {
    setFieldValues({ ...fieldDefaults });
    setFormErrors({});
    setCreditsData(null);
    setContributorPk(null);
  };

  const updateFieldValues = data => {
    const fieldVals = Object.entries(data).reduce((result, [key, val]) => {
      if (fieldDefaults.hasOwnProperty(key)) {
        result[key] = val;
      }
      return result;
    }, {});

    setFieldValues(fieldVals);
  };

  useEffect(() => {
    if (isOpen) {
      if (contributorId) loadFormData();
    } else {
      clearFormData();
    }
  }, [isOpen, contributorId]);

  const nonFieldErrors = formErrors.nonFieldErrors && formErrors.nonFieldErrors.length > 0 && (
    <div className="error-list mb-4">
      {formErrors.nonFieldErrors.map((msg, idx) => <p key={idx}>{msg}</p>)}
    </div>
  );

  const fullProfileUrl = urlJoin(urls.contributors, contributorPk || '', '/');

  return renderPortal(
    <>
      <div className={classNames('custom-title-builder-child-form-modal-mask', isOpen && 'open')} />
      <div className={classNames('custom-title-builder-child-form-modal', isOpen && 'open')}>
        <header className="custom-title-builder-child-form-modal-header">
          <a href="#back" className="back-icon" onClick={e => { e.preventDefault(); handleFormClose(); }}>
            <Icon name="arrow_back_ios" size={32} />
          </a>
          <h3>{isNew ? 'New' : 'Editing'} <em>Contributor</em> Item</h3>
        </header>
        <div className="custom-title-builder-child-form-modal-content">
          {creditsData && (creditsData.media.length > 0 || creditsData.titles.length > 0) && (
            <div className="mb-4">
              <Message type="info" clearable={false} text="Please note: changes will apply to all titles and media that reference this contributor." />
            </div>
          )}
          <div className="d-flex">
            <div className="pb-4" style={{ position: 'relative', flex: 1 }}>
              {nonFieldErrors}
              <form noValidate onSubmit={handleSubmit}>
                <div className="columns">
                  <div className="column col-6">
                    <FieldWrapper
                      name="contributorForm-firstName"
                      label="First Name"
                      errors={formErrors.firstName}
                    >
                      <input
                        type="text"
                        className="form-input"
                        name="contributorForm-firstName"
                        value={fieldValues.firstName}
                        onChange={handleFieldChange}
                      />
                    </FieldWrapper>
                  </div>

                  <div className="column col-6">
                    <FieldWrapper
                      name="contributorForm-middleName"
                      label="Middle Name"
                      errors={formErrors.middleName}
                    >
                      <input
                        type="text"
                        className="form-input"
                        name="contributorForm-middleName"
                        value={fieldValues.middleName}
                        onChange={handleFieldChange}
                      />
                    </FieldWrapper>
                  </div>

                  <div className="column col-6">
                    <FieldWrapper
                      name="contributorForm-lastName"
                      label="Last Name"
                      errors={formErrors.lastName}
                    >
                      <input
                        type="text"
                        className="form-input"
                        name="contributorForm-lastName"
                        value={fieldValues.lastName}
                        onChange={handleFieldChange}
                      />
                    </FieldWrapper>
                  </div>

                  <div className="column col-6">
                    <FieldWrapper
                      name="contributorForm-bioUrl"
                      label="Bio URL"
                      errors={formErrors.bioUrl}
                    >
                      <input
                        type="text"
                        className="form-input"
                        name="contributorForm-bioUrl"
                        value={fieldValues.bioUrl}
                        onChange={handleFieldChange}
                      />
                    </FieldWrapper>
                  </div>

                  <div className="column col-6">
                    <FieldWrapper
                      name="contributorForm-organization"
                      label="Organization"
                      errors={formErrors.organization}
                    >
                      <input
                        type="text"
                        className="form-input"
                        name="contributorForm-organization"
                        value={fieldValues.organization}
                        onChange={handleFieldChange}
                      />
                    </FieldWrapper>
                  </div>

                  <div className="column col-6">
                    <FieldWrapper
                      name="contributorForm-organizationUrl"
                      label="Organization URL"
                      errors={formErrors.organizationUrl}
                    >
                      <input
                        type="text"
                        className="form-input"
                        name="contributorForm-organizationUrl"
                        value={fieldValues.organizationUrl}
                        onChange={handleFieldChange}
                      />
                    </FieldWrapper>
                  </div>
                </div>

                <FieldWrapper
                  name="contributorForm-bio"
                  label="Bio"
                  errors={formErrors.bio}
                >
                  <QuillEditor
                    size="small"
                    value={fieldValues.bio}
                    onChange={handleBioChange}
                  />
                </FieldWrapper>

                <FieldWrapper name="contributorForm-bioMedia" label="Image" errors={formErrors.bioMedia}>
                  <LibraryMediaField
                    inputName="contributorForm-bioMedia"
                    buttonText="Choose Image"
                    limitTypes={['image']}
                    value={fieldValues.bioMedia || ''}
                    onChange={handleBioMediaChange}
                  />
                </FieldWrapper>

                <div className="mb-3">
                  <Message type="warning" clearable={false} text={`To edit additional profile details for this contributor, please visit the <a href="${fullProfileUrl}" target="_blank">Manage Contributors</a> area.`} />
                </div>

                <hr />
                <div>
                  <button type="button" className="btn mr-2" onClick={onCancel}>Cancel</button>
                  <button type="submit" className="btn btn-primary" disabled={formDataFetching}>Save Changes</button>
                </div>
              </form>
              <LoadingOverlay show={formDataFetching} style={{ backgroundColor: 'rgba(247, 247, 247, .7)' }} />
            </div>
            <div className="object-detail-sidebar" style={{ flex: '0 0 380px' }}>
              <h4 className="alt-head mb-1">Credits</h4>
              <hr className="mt-0 mb-2" />
              {creditsData && (
                <>
                  <p className="mb-4">
                    <em>
                      {(creditsData.media.length > 0 || creditsData.titles.length > 0)
                        ? 'This contributor is credited on the following items:'
                        : 'This contributor is not currently credited on any items.'}
                    </em>
                  </p>

                  {creditsData.media.length > 0 && (
                    <>
                      <h5 className="mb-1">Media</h5>
                      <div className="mb-4">
                        {creditsData.media.map(({ name, url }, idx) => (
                          <div key={idx} className="mb-1"><a href={url} target="_blank" rel="noopener noreferrer">{name}</a></div>
                        ))}
                      </div>
                    </>
                  )}

                  {creditsData.titles.length > 0 && (
                    <>
                      <h5 className="mb-1">Titles</h5>
                      <div className="mb-4">
                        {creditsData.titles.map(({ name, url }, idx) => (
                          <div key={idx} className="mb-1"><a href={url} target="_blank" rel="noopener noreferrer">{name}</a></div>
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>,
  );
};

ContributorForm.propTypes = {
  csrfToken: PropTypes.string,
  containerEl: PropTypes.instanceOf(Element),
  contributorId: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export default withCSRF(ContributorForm);
