import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid4 } from 'uuid';
import camelize from 'camelize';
import throttle from 'lodash/throttle';
import StyledScrollbar from 'components/common/StyledScrollbar';
import ErrorBoundary from 'components/common/ErrorBoundary';
import Icon from 'components/common/Icon';
import { ManagementForm } from 'components/common/inlines';
import InlineListBuilder, { Controls as ListBuilderControls } from 'components/common/inlines/InlineListBuilder';
import { ObjectListModal } from 'components/views/ObjectList';
import ItemSettingsForm from './ItemSettingsForm';
import PlaylistItem from './PlaylistItem';


class PlaylistBuilder extends Component {
  constructor (props) {
    super(props);

    this.state = {
      itemsById: {},
      itemsOrdered: [],
      itemGroups: {},
      editMode: (props.formsetData.forms.length === 0 || props.hasError) ? 'insert' : 'sort',
      mediaModalCallback: () => null,
      mediaModalOpen: false,
      activeItemId: null,
    };

    this.nodeTypes = [
      { name: 'Media', callback: this.handleInsertMediaClick.bind(this) },
      { name: 'Group', callback: this.handleInsertGroup.bind(this) },
      { name: 'Label', callback: this.handleInsertLabel.bind(this) },
    ];

    this.nextItemIndex = 0;

    this.handleEditModeChange = this.handleEditModeChange.bind(this);
    this.handleInsertLabel = this.handleInsertLabel.bind(this);
    this.handleInsertGroup = this.handleInsertGroup.bind(this);
    this.handleInsertMediaClick = this.handleInsertMediaClick.bind(this);
    this.insertMedia = this.insertMedia.bind(this);
    this.handleInsertGroupMediaClick = this.handleInsertGroupMediaClick.bind(this);
    this.insertGroupMedia = this.insertGroupMedia.bind(this);
    this.handleItemSettingsClick = this.handleItemSettingsClick.bind(this);
    this.handleSettingsFormChange = this.handleSettingsFormChange.bind(this);
    this.handleMediaModalRequestClose = this.handleMediaModalRequestClose.bind(this);
    this.handleItemMove = throttle(this.handleItemMove.bind(this), 100);
    this.handleItemPreviewLoad = this.handleItemPreviewLoad.bind(this);
  }

  componentDidMount () {
    this.initItems();
  }

  componentDidUpdate (prevProps, prevState) {
    const { editMode } = this.state;
    // Close open settings dialog when switching back to 'insert' mode
    if (editMode === 'sort' && prevState.editMode === 'insert') {
      this.setState({ activeItemId: null });
    }
  }

  handleEditModeChange (editMode) {
    this.setState({ editMode });
  }

  handleInsertLabel (idx) {
    const newItem = this.initNewItem('label');
    const newIndex = this.getPaddedIndex(idx);
    this.insertItems([newItem], newIndex);
  }

  handleInsertGroup (idx) {
    const newItem = this.initNewItem('group');
    const newIndex = this.getPaddedIndex(idx);
    this.insertItems([newItem], newIndex);
  }

  handleInsertMediaClick (idx) {
    const cb = items => {
      this.insertMedia(idx, items);
      this.handleMediaModalRequestClose();
    };

    this.setState({
      mediaModalCallback: cb,
      mediaModalOpen: true,
    });
  }

  insertMedia (startIndex, items) {
    const newStartIndex = this.getPaddedIndex(startIndex);
    const newItems = items.map(item => this.initNewItem('media', item.uuid));
    this.insertItems(newItems, newStartIndex);
  }

  handleInsertGroupMediaClick (groupUid, idx) {
    const cb = items => {
      this.insertGroupMedia(groupUid, idx, items);
      this.handleMediaModalRequestClose();
    };

    this.setState({
      mediaModalCallback: cb,
      mediaModalOpen: true,
    });
  }

  insertGroupMedia (groupUid, startIndex, items) {
    const { itemsOrdered } = this.state;
    const globalIndex = itemsOrdered.indexOf(groupUid) + 1 + startIndex;
    const newItems = items.map(item => this.initNewItem('media', item.uuid, groupUid));
    this.insertItems(newItems, globalIndex);
  }

  handleItemSettingsClick (itemId) {
    const { activeItemId } = this.state;
    this.setState({ activeItemId: activeItemId === itemId ? null : itemId });
  }

  handleSettingsFormChange (fields) {
    const { activeItemId, itemsById } = this.state;
    const origItem = this.getItem(activeItemId);
    const updatedItem = {
      ...origItem,
      fields: {
        ...origItem.fields,
        ...fields,
      },
    };

    this.setState({
      itemsById: {
        ...itemsById,
        [activeItemId]: updatedItem,
      },
    });
  }

  handleMediaModalRequestClose () {
    this.setState({ mediaModalOpen: false });
  }

  handleItemMove (sourceId, targetId, insertPos) {
    const { itemsOrdered, itemsById } = this.state;

    const sourceItem = itemsById[sourceId];
    const targetItem = itemsById[targetId];

    if (targetItem && targetItem.fields.groupUid && sourceItem.fields.type !== 'media') {
      // Only media can be nested into a group.
      return;
    }

    let newItemsOrdered;
    let toIndex;
    let targetGroupId = targetItem ? targetItem.fields.groupUid : null;
    if (sourceItem.fields.type === 'group') {
      // We are moving a group, so need to reindex all child items.
      const subItemIds = itemsOrdered.filter(id => itemsById[id].fields.groupUid === sourceId);
      newItemsOrdered = itemsOrdered.filter(id => id !== sourceId && !subItemIds.includes(id));
      toIndex = insertPos === 'before' ? newItemsOrdered.indexOf(targetId) : newItemsOrdered.indexOf(targetId) + 1;
      newItemsOrdered.splice(toIndex, 0, sourceId, ...subItemIds);
    } else if (targetId.indexOf('placeholder') > -1) {
      // We are moving an item into an empty group.
      if (sourceItem.fields.type !== 'media') {
        return;
      }
      // TODO - something better here
      targetGroupId = targetId.match(/group-(.+)-placeholder/)[1];
      newItemsOrdered = itemsOrdered.filter(item => item !== sourceId);
      toIndex = itemsOrdered.indexOf(targetGroupId) + 1;
      newItemsOrdered.splice(toIndex, 0, sourceId);
    } else {
      newItemsOrdered = itemsOrdered.filter(item => item !== sourceId);
      toIndex = insertPos === 'before' ? newItemsOrdered.indexOf(targetId) : newItemsOrdered.indexOf(targetId) + 1;
      newItemsOrdered.splice(toIndex, 0, sourceId);
    }

    this.setState({
      itemsOrdered: newItemsOrdered,
      itemsById: {
        ...itemsById,
        [sourceId]: {
          ...sourceItem,
          fields: {
            ...sourceItem.fields,
            groupUid: targetGroupId,
          },
        },
      },
    });
  }

  handleItemPreviewLoad (itemId, obj) {
    const { itemDetails } = this.state;
    const newState = {
      itemDetails: {
        ...itemDetails,
        [itemId]: {
          label: obj.name,
          type: obj.type,
        },
      },
    };
    this.setState(newState);
  }

  getPaddedIndex (idx) {
    // For a given top-level index, return a padded index that includes
    // all preceding items contained in groups.
    const { itemsOrdered } = this.state;
    if (idx === 0) {
      return idx;
    }

    const nextItem = this.getItems().filter(item => !item.fields.groupUid)[idx];
    if (nextItem) {
      return itemsOrdered.indexOf(nextItem.itemId);
    } else {
      return itemsOrdered.length;
    }
  }

  initNewItem (type, mediaId = null, group = null) {
    const { parentId, formsetData: { prefix } } = this.props;
    const itemId = uuid4();
    const item = {
      itemId,
      prefix: `${prefix}-${this.nextItemIndex}`,
      errors: {},
      fields: {
        id: null,
        index: null,
        type,
        groupUid: group,
        media: mediaId,
        name: '',
        slug: '',
        description: '',
        parent: parentId,
        uuid: itemId,
        posterDuration: 0,
      },
    };

    this.nextItemIndex += 1;
    return item;
  }

  initItems () {
    // Hydrate items list from initial formset data
    const { forms } = camelize(this.props.formsetData);

    const sortedForms = [...forms].sort((a, b) => (
      parseInt(a.fields.index.value, 10) - parseInt(b.fields.index.value, 10)
    ));

    const itemsById = {};
    const itemsOrdered = [];
    let shouldDelete = false;
    sortedForms.forEach(form => {
      const itemId = (form.isBound ? form.fields.uuid.value : form.fields.uuid.initial) || uuid4();
      const fields = Object.keys(form.fields).reduce((result, key) => {
        if (key === 'DELETE') {
          shouldDelete = !!form.fields[key].value;
        } else {
          result[key] = form.fields[key].value;
        }
        return result;
      }, {});
      fields.index = this.nextItemIndex;

      const item = {
        itemId,
        fields,
        shouldDelete,
        prefix: form.prefix,
        errors: form.errors,
      };

      itemsById[itemId] = item;
      itemsOrdered.push(itemId);
      this.nextItemIndex += 1;
    });
    this.setState({ itemsById, itemsOrdered });
  }

  insertItems (items, idx) {
    const { itemsById, itemsOrdered } = this.state;

    const newItems = items.reduce((result, item) => {
      result[item.itemId] = item;
      return result;
    }, {});
    const newItemIds = items.map(({ itemId }) => itemId);

    this.setState({
      itemsById: {
        ...itemsById,
        ...newItems,
      },
      itemsOrdered: [...itemsOrdered.slice(0, idx), ...newItemIds, ...itemsOrdered.slice(idx)],
    });
  }

  getItems () {
    const { itemsOrdered, itemsById } = this.state;
    return itemsOrdered.map(id => itemsById[id]);
  }

  getItem (itemId) {
    const { itemsById } = this.state;
    return itemsById[itemId];
  }

  getSettingsFormInitialValues () {
    const { activeItemId } = this.state;
    const activeItem = this.getItem(activeItemId);
    const {
      slug,
      posterDuration,
    } = activeItem.fields;
    return {
      slug,
      posterDuration,
    };
  }

  renderItems () {
    const { parentId } = this.props;
    const { editMode, itemsOrdered, activeItemId } = this.state;
    const itemsArray = this.getItems();

    return itemsArray.filter(item => !item.fields.groupUid).map(({ itemId, ...rest }) => {
      const subItems = itemsArray.filter(item => item.fields.groupUid === itemId).map(item => ({ ...item, itemIndex: itemsOrdered.indexOf(item.itemId) }));
      const itemIndex = itemsOrdered.indexOf(itemId);
      const subItemCallback = this.handleInsertGroupMediaClick.bind(this, itemId);
      return (
        <PlaylistItem
          key={itemId}
          itemId={itemId}
          itemIndex={itemIndex}
          parentId={parentId}
          editMode={editMode}
          subItems={subItems}
          activeItemId={activeItemId}
          settingsActive={activeItemId === itemId}
          onSettingsClick={this.handleItemSettingsClick}
          onSubItemMove={this.handleItemMove}
          onLoadPreview={this.handleItemPreviewLoad}
          onAddMedia={subItemCallback}
          {...rest}
        />
      );
    });
  }

  render () {
    const { prefix, managementForm } = camelize(this.props.formsetData);
    const { itemsOrdered, itemsById, editMode, mediaModalCallback, mediaModalOpen, activeItemId, itemDetails } = this.state;
    const showSettingsForm = !!activeItemId;
    let settingsFormTitle;
    if (showSettingsForm) {
      settingsFormTitle = (itemDetails[activeItemId] || {}).label;
    }
    const formCount = itemsOrdered.length;
    const ungroupedItemIds = itemsOrdered.filter(itemId => !itemsById[itemId].fields.groupUid);

    return (
      <div className="title-builder player-builder object-detail-panel-full-height">
        <ErrorBoundary>
          <ManagementForm prefix={prefix} formData={managementForm} totalForms={formCount} />
          <div style={{ flex: 1, height: '100%' }}>
            <StyledScrollbar>
              <div style={{ padding: 30 }}>
                <ListBuilderControls mode={editMode} empty={itemsOrdered.length === 0} onChangeMode={this.handleEditModeChange} />
                <InlineListBuilder
                  editMode={editMode}
                  nodeTypes={this.nodeTypes}
                  itemIds={ungroupedItemIds}
                  onItemMove={this.handleItemMove}
                >
                  {this.renderItems()}
                </InlineListBuilder>

                {itemsOrdered.length === 0 ? (
                  <div className="empty my-4">
                    <div className="empty-icon">
                      <Icon name="list" size={60} />
                    </div>
                    <p className="empty-title h3">Empty Playlist</p>
                    <p className="empty-subtitle">Add items to get started.</p>
                  </div>
                ) : null}

                <ObjectListModal
                  model="media"
                  preFilter={{ type__in: 'audio,video' }}
                  disabledFilters={['type', 'width', 'height']}
                  isOpen={mediaModalOpen}
                  onAccept={mediaModalCallback}
                  onRequestClose={this.handleMediaModalRequestClose}
                />
              </div>
            </StyledScrollbar>
          </div>
          <div style={{ flex: '0 0 509px' }}>
            {showSettingsForm && (
              <ItemSettingsForm
                formTitle={settingsFormTitle}
                initialValues={this.getSettingsFormInitialValues()}
                onChange={this.handleSettingsFormChange}
              />
            )}
          </div>
        </ErrorBoundary>
      </div>
    );
  }
}

PlaylistBuilder.propTypes = {
  formsetData: PropTypes.object,
  parentId: PropTypes.string,
  hasError: PropTypes.bool,
};

PlaylistBuilder.defaultProps = {

};

export default PlaylistBuilder;
