import ReactHabitat from 'react-habitat';
import { ReduxDomFactory } from 'react-habitat-redux';
import { routedComponent } from 'routing';
import store from 'store';
import Message from 'components/common/Message';
import Icon from 'components/common/Icon';
import TabNav from 'components/common/TabNav';
import MediaPlayer from 'components/common/MediaPlayer';
import MediaTextTracks from 'components/common/MediaTextTracks';
import PreviewImage from 'components/common/PreviewImage';
import ActionMenu from 'components/common/ActionMenu';
import ButtonMenu from 'components/common/ButtonMenu';
import ObjectDelete from 'components/common/ObjectDelete';
import ObjectDuplicate from 'components/common/ObjectDuplicate';
import PageHeader from 'components/common/PageHeader';
import ReleaseEmbedCode from 'components/common/ReleaseEmbedCode';
import ReleasePublishStatus from 'components/common/ReleasePublishStatus';
import QuillEditor from 'components/common/QuillEditor';
import LibraryMediaField from 'components/common/LibraryMediaField';
import PreserveParamsField from 'components/common/PreserveParamsField';
import DjangoFieldWrapper from 'components/common/DjangoFieldWrapper';
import MapboxWidget from 'components/common/MapboxWidget';
import CreditsWidget from 'components/common/CreditsWidget';
import RelatedLinksFormset from 'components/common/RelatedLinksFormset';
import LocationField from 'components/common/LocationField';
import PublishEndpointField from 'components/common/PublishEndpointField';
import ReleaseBulkPublish from 'components/common/ReleaseBulkPublish';
import MapZoomField from 'components/common/MapZoomField';
import MapViewportField from 'components/common/MapViewportField';
import FlexDateField from 'components/common/FlexDateField';
import FontField from 'components/common/FontField';
import PrerollOptions from 'components/common/PrerollOptions';
import TagField from 'components/common/TagField';
import SEOFields from 'components/common/SEOFields';
import JobProcessingIndicator from 'components/common/JobProcessingIndicator';
import { InlineFormset } from 'components/common/inlines';
import ObjectList, { Filters } from 'components/views/ObjectList';
import MediaImport from 'components/views/MediaImport';
import ObjectCreate from 'components/views/ObjectCreate';
import ReleasePublish from 'components/views/ReleasePublish';
import PlaylistBuilder from 'components/views/PlaylistBuilder';
import TimelineBuilder from 'components/views/TimelineBuilder';
import MapBuilder from 'components/views/MapTitle/MapBuilder';
import MarkerTypesFormset from 'components/views/MapTitle/MarkerTypesFormset';
import SlideshowBuilder from 'components/views/SlideshowBuilder';
import ContributorsBuilder from 'components/views/ContributorsBuilder';
import CoverBuilder from 'components/views/CoverBuilder';
import CoverLayout from 'components/views/CoverLayout';
import ObjectPreview from 'components/views/ObjectPreview';
import CustomTitleBuilder from 'components/views/CustomTitleBuilder';
import SiteBuilder from 'components/views/SiteBuilder';
import PlayerBuilder from 'components/views/PlayerBuilder';
import NavBuilder from 'components/views/NavBuilder';
import NavigationField from 'components/common/NavigationField';
import RedirectsField from 'components/common/RedirectsField';
import SortableJsonKeyValueField from 'components/common/SortableJsonKeyValueField';
import JsonListField from 'components/common/JsonListField';
import AnnotationBuilder from 'components/views/AnnotationBuilder';
import MenuBuilder from 'components/views/MenuBuilder';
import StructuredContentBuilder from 'components/views/StructuredContentBuilder';
import StructuredContentField from 'components/common/StructuredContentField';
import ReleaseTemplateSelect from 'components/views/ReleaseTemplateSelect';
import ReleaseSourceSelect from 'components/views/ReleaseSourceSelect';
import { FacebookReleaseCompose, FacebookReleaseRemove } from 'components/views/FacebookRelease';
import Integrations from 'components/views/Integrations';
import TemplatePresets from 'components/views/TemplatePresets';
import FilesPanel from 'components/views/FilesPanel';
import { ObjectAnalytics } from 'components/views/Analytics';
import { MediaMetadataForm, ReleaseMetadataForm, TitleMetadataForm } from 'components/views/metadataForm';
import 'globals';
import '../scss/main.scss';


class App extends ReactHabitat.Bootstrapper {
  constructor () {
    super();
    const builder = new ReactHabitat.ContainerBuilder();
    builder.factory = new ReduxDomFactory(store);

    builder.register(Message).as('Message');
    builder.register(Icon).as('Icon');
    builder.register(routedComponent(TabNav)).as('TabNav');
    builder.register(MediaPlayer).as('MediaPlayer');
    builder.register(MediaTextTracks).as('MediaTextTracks');
    builder.register(PreviewImage).as('PreviewImage');
    builder.register(ActionMenu).as('ActionMenu');
    builder.register(ButtonMenu).as('ButtonMenu');
    builder.register(ObjectDelete).as('ObjectDelete');
    builder.register(ObjectDuplicate).as('ObjectDuplicate');
    builder.register(PageHeader).as('PageHeader');
    builder.register(ReleaseEmbedCode).as('ReleaseEmbedCode');
    builder.register(ReleasePublishStatus).as('ReleasePublishStatus');
    builder.register(QuillEditor).as('QuillEditor');
    builder.register(LibraryMediaField).as('LibraryMediaField');
    builder.register(routedComponent(PreserveParamsField)).as('PreserveParamsField');
    builder.register(DjangoFieldWrapper).as('DjangoFieldWrapper');
    builder.register(MapboxWidget).as('MapboxWidget');
    builder.register(CreditsWidget).as('CreditsWidget');
    builder.register(RelatedLinksFormset).as('RelatedLinksFormset');
    builder.register(LocationField).as('LocationField');
    builder.register(PublishEndpointField).as('PublishEndpointField');
    builder.register(ReleaseBulkPublish).as('ReleaseBulkPublish');
    builder.register(MapZoomField).as('MapZoomField');
    builder.register(MapViewportField).as('MapViewportField');
    builder.register(FlexDateField).as('FlexDateField');
    builder.register(FontField).as('FontField');
    builder.register(PrerollOptions).as('PrerollOptions');
    builder.register(TagField).as('TagField');
    builder.register(SEOFields).as('SEOFields');
    builder.register(JobProcessingIndicator).as('JobProcessingIndicator');
    builder.register(routedComponent(ObjectList)).as('ObjectList');
    builder.register(routedComponent(Filters)).as('ObjectListFilters');
    builder.register(MediaImport).as('MediaImport');
    builder.register(ObjectCreate).as('ObjectCreate');
    builder.register(ReleasePublish).as('ReleasePublish');
    builder.register(InlineFormset).as('InlineFormset');
    builder.register(PlaylistBuilder).as('PlaylistBuilder');
    builder.register(TimelineBuilder).as('TimelineBuilder');
    builder.register(MapBuilder).as('MapBuilder');
    builder.register(MarkerTypesFormset).as('MarkerTypesFormset');
    builder.register(routedComponent(SlideshowBuilder)).as('SlideshowBuilder');
    builder.register(ContributorsBuilder).as('ContributorsBuilder');
    builder.register(CoverBuilder).as('CoverBuilder');
    builder.register(CoverLayout).as('CoverLayout');
    builder.register(ObjectPreview).as('ObjectPreview');
    builder.register(CustomTitleBuilder).as('CustomTitleBuilder');
    builder.register(SiteBuilder).as('SiteBuilder');
    builder.register(PlayerBuilder).as('PlayerBuilder');
    builder.register(NavBuilder).as('NavBuilder');
    builder.register(NavigationField).as('NavigationField');
    builder.register(RedirectsField).as('RedirectsField');
    builder.register(SortableJsonKeyValueField).as('SortableJsonKeyValueField');
    builder.register(JsonListField).as('JsonListField');
    builder.register(AnnotationBuilder).as('AnnotationBuilder');
    builder.register(MenuBuilder).as('MenuBuilder');
    builder.register(StructuredContentBuilder).as('StructuredContentBuilder');
    builder.register(StructuredContentField).as('StructuredContentField');
    builder.register(ReleaseTemplateSelect).as('ReleaseTemplateSelect');
    builder.register(ReleaseSourceSelect).as('ReleaseSourceSelect');
    builder.register(FacebookReleaseCompose).as('FacebookReleaseCompose');
    builder.register(FacebookReleaseRemove).as('FacebookReleaseRemove');
    builder.register(Integrations).as('Integrations');
    builder.register(TemplatePresets).as('TemplatePresets');
    builder.register(FilesPanel).as('FilesPanel');
    builder.register(ObjectAnalytics).as('ObjectAnalytics');
    builder.register(MediaMetadataForm).as('MediaMetadataForm');
    builder.register(ReleaseMetadataForm).as('ReleaseMetadataForm');
    builder.register(TitleMetadataForm).as('TitleMetadataForm');

    this.setContainer(builder.build());
  }
}

export default new App();
