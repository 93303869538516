import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import ErrorBoundary from 'components/common/ErrorBoundary';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { MediaImportModal } from 'components/views/MediaImport';
import Icon from 'components/common/Icon';

const UpdateMediaSource = ({ id, workspaceAdmin = false }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [isReloading, setIsReloading] = useState(false);

  const hideModal = () => setModalVisible(false);

  const handleLinkClick = evt => {
    evt.preventDefault();
    setModalVisible(true);
  };

  const handleImportComplete = () => {
    setIsReloading(true);
    hideModal();
    window.location.reload();
  };

  const loadingOverlay = createPortal(
    <LoadingOverlay show={isReloading} style={{ backgroundColor: 'rgba(247, 247, 247, .7)' }} />,
    document.querySelector('.object-detail-container'),
  );

  return (
    <ErrorBoundary>
      <a href="#import" onClick={handleLinkClick}>
        <Icon name="attach_file_add" className="mr-2" />
        <span>Update Media Source</span>
      </a>
      <MediaImportModal
        title="Update Media Source"
        workspaceAdmin={workspaceAdmin}
        instanceId={id}
        isOpen={modalVisible}
        onRequestClose={hideModal}
        onImportComplete={handleImportComplete}
      />
      {loadingOverlay}
    </ErrorBoundary>
  );
};

UpdateMediaSource.propTypes = {
  id: PropTypes.string,
  workspaceAdmin: PropTypes.bool,
};

export default UpdateMediaSource;
