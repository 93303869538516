import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelize from 'camelize';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import { formatFlexDate } from 'utils';
import {
  MEDIA_SOURCE_URL, MEDIA_SOURCE_LIBRARY,
  VIMEO_URL_PATTERN, YOUTUBE_URL_PATTERN, SOUNDCLOUD_URL_PATTERN,
} from './';
import BreakText from 'components/common/BreakText';
import Icon from 'components/common/Icon';
import { VimeoIcon, YouTubeIcon, SoundCloudIcon } from 'components/common/serviceIcons';
import TruncateText from 'components/common/TruncateText';
import { DeleteOverlay } from 'components/common/inlines';


class Item extends Component {
  constructor (props) {
    super(props);
    this.state = {
      mediaObject: null,
      error: false,
      processing: false,
    };

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount () {
    const { media } = this.props;
    media && this.fetchData(media);
  }

  componentDidUpdate (prevProps, prevState) {
    const { media } = this.props;
    if (media && media !== prevProps.media) {
      this.fetchData(media);
    }
  }

  handleDeleteClick (e) {
    const { onDeleteClick } = this.props;
    e.stopPropagation();
    onDeleteClick();
  }

  fetchData (mediaId) {
    const url = urlJoin(urls.mediaDataBase, mediaId || '', '/', '?format=json');
    this.setState({ processing: true });
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        this.setState({ mediaObject: data, processing: false });
      })
      .catch(err => {
        this.setState({ processing: false });
        console.error(err);
      });
  }

  renderThumbnail () {
    const { mediaSource, media, mediaUrl, layout } = this.props;
    const { mediaObject } = this.state;


    if (mediaSource === MEDIA_SOURCE_LIBRARY && media) {
      return mediaObject ? (
        <div className="title-builder-item-thumbnail mr-4">
          <img src={mediaObject.thumbnail} alt="thumbnail" width="100" height="60" />
        </div>
      ) : (
        <div className="title-builder-item-thumbnail mr-4">
          <Icon name="steppers" />
        </div>
      );
    } else if (mediaSource === MEDIA_SOURCE_URL && mediaUrl) {
      if (VIMEO_URL_PATTERN.test(mediaUrl)) {
        return (
          <div className="title-builder-item-thumbnail vimeo mr-4">
            <VimeoIcon fill="currentColor" />
          </div>
        );
      } else if (YOUTUBE_URL_PATTERN.test(mediaUrl)) {
        return (
          <div className="title-builder-item-thumbnail youtube mr-4">
            <YouTubeIcon fill="currentColor" />
          </div>
        );
      } else if (SOUNDCLOUD_URL_PATTERN.test(mediaUrl)) {
        return (
          <div className="title-builder-item-thumbnail soundcloud mr-4">
            <SoundCloudIcon fill="currentColor" />
          </div>
        );
      }
    } else if (layout === 'quote') {
      return (
        <div className="title-builder-item-thumbnail mr-4">
          <Icon name="format_quote" fill size={36} />
        </div>
      );
    } else {
      return (
        <div className="title-builder-item-thumbnail mr-4">
          <Icon name="content_copy" />
        </div>
      );
    }
  }

  renderFormFields () {
    const {
      id, parent, year, month, day, time, category, dateDisplay, headline, description, layout, quoteAttribution,
      mediaSource, media, mediaUrl, mediaDescription, mediaCredit, mediaDescriptionInherit, mediaCreditInherit,
      location, locationInherit, shouldDelete, prefix,
    } = this.props;

    return (
      <>
        {id ? <input type="hidden" name={`${prefix}-id`} value={id} /> : null}
        {shouldDelete ? <input type="hidden" name={`${prefix}-DELETE`} value="1" /> : null}
        <input type="hidden" name={`${prefix}-parent`} value={parent} />
        <input type="hidden" name={`${prefix}-year`} value={year || ''} />
        <input type="hidden" name={`${prefix}-month`} value={month || ''} />
        <input type="hidden" name={`${prefix}-day`} value={day || ''} />
        <input type="hidden" name={`${prefix}-time`} value={time || ''} />
        <input type="hidden" name={`${prefix}-category`} value={category || ''} />
        <input type="hidden" name={`${prefix}-date_display`} value={dateDisplay || ''} />
        <input type="hidden" name={`${prefix}-headline`} value={headline || ''} />
        <input type="hidden" name={`${prefix}-description`} value={description || ''} />
        <input type="hidden" name={`${prefix}-layout`} value={layout || ''} />
        <input type="hidden" name={`${prefix}-media_source`} value={mediaSource || ''} />
        <input type="hidden" name={`${prefix}-media`} value={media || ''} />
        <input type="hidden" name={`${prefix}-media_url`} value={mediaUrl || ''} />
        <input type="hidden" name={`${prefix}-quote_attribution`} value={quoteAttribution || ''} />
        <input type="hidden" name={`${prefix}-media_description`} value={mediaDescription || ''} />
        <input type="hidden" name={`${prefix}-media_credit`} value={mediaCredit || ''} />
        <input type="hidden" name={`${prefix}-media_description_inherit`} value={mediaDescriptionInherit} />
        <input type="hidden" name={`${prefix}-media_credit_inherit`} value={mediaCreditInherit} />
        <input type="hidden" name={`${prefix}-location`} value={location} />
        <input type="hidden" name={`${prefix}-location_inherit`} value={locationInherit} />
      </>
    );
  }

  render () {
    const { counter, active, error, onClick, headline, media, mediaUrl, year, month, day, time, dateDisplay, shouldDelete } = this.props;
    const { mediaObject } = this.state;

    let formattedDate = '';
    try {
      formattedDate = formatFlexDate({ year, month, day, time });
    } catch (e) {
      console.error(e);
    }

    const containerClasses = classNames({
      active,
      error,
      'title-builder-item': true,
      'to-delete': shouldDelete,
    });

    return (
      <div className={containerClasses} onClick={onClick}>
        <div className="index-counter">{counter}</div>
        {this.renderThumbnail()}

        <div className="title-builder-item-details">
          <div className="date">{dateDisplay || formattedDate}</div>
          <h6 className="mb-0"><TruncateText text={headline} numChars={75} /></h6>

          {media ? (
            <div className="meta mt-1 lh-1">
              Media:&nbsp;
              {mediaObject ? (
                <a href={`${urls.mediaBase}${mediaObject.id}/`} target="_blank" rel="noopener noreferrer">{mediaObject.id}</a>
              ) : null}
            </div>
          ) : null}

          {mediaUrl ? (
            <div className="meta lh-1">
              Media: <a href={mediaUrl} target="_blank" rel="noopener noreferrer"><BreakText text={mediaUrl} /></a>
            </div>
          ) : null}
        </div>

        {this.renderFormFields()}

        {shouldDelete ? <DeleteOverlay onUndoClick={this.handleDeleteClick} /> : null}

        <span
          className="btn-delete tooltip tooltip-left"
          key={shouldDelete}
          onClick={this.handleDeleteClick}
          data-tooltip={shouldDelete ? 'Undo Remove' : 'Remove Slide'}
        >
          {shouldDelete ? <Icon name="undo" /> : <Icon name="delete" fill />}
        </span>
      </div>
    );
  }
}

Item.propTypes = {
  counter: PropTypes.number,
  active: PropTypes.bool,
  error: PropTypes.bool,
  onClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  headline: PropTypes.string,
  dateDisplay: PropTypes.string,
  id: PropTypes.string,
  parent: PropTypes.string,
  year: PropTypes.number,
  month: PropTypes.number,
  day: PropTypes.number,
  time: PropTypes.string,
  category: PropTypes.number,
  description: PropTypes.string,
  layout: PropTypes.string,
  mediaSource: PropTypes.string,
  media: PropTypes.string,
  mediaUrl: PropTypes.string,
  quoteAttribution: PropTypes.string,
  shouldDelete: PropTypes.bool,
  prefix: PropTypes.string,
  mediaDescription: PropTypes.string,
  mediaCredit: PropTypes.string,
  mediaDescriptionInherit: PropTypes.bool,
  mediaCreditInherit: PropTypes.bool,
  location: PropTypes.string,
  locationInherit: PropTypes.bool,
};

export default Item;
