import React from 'react';
import PropTypes from 'prop-types';
import { previewImageVersions } from 'app-constants';
import Select from 'components/common/Select';

const ImageVersionSelect = ({
  includeOriginal = true,
  value,
  onChange,
}) => {
  const imageVersionOptions = [
    ...(includeOriginal ? [{ value: 'original', label: 'Original' }] : []),
    ...previewImageVersions.map(({ slug, label }) => ({ value: slug, label })),
  ];
  const imageVersionSelectVal = imageVersionOptions.find(opt => opt.value === value);

  return (
    <Select
      value={imageVersionSelectVal}
      options={imageVersionOptions}
      onChange={onChange}
    />
  );
};

ImageVersionSelect.propTypes = {
  includeOriginal: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default ImageVersionSelect;
