module.exports = Object.assign({}, {
  shortDateFormat: 'P', // https://date-fns.org/v2.12.0/docs/format
  shortDateTimeFormat: 'Pp',
  filterParamDateFormat: 'yyyy-MM-dd',
  // flexDateFormats should align with the values defined in the backend:
  // msp_core.models.base.FlexibleDateBase.DATETIME_FORMATS
  flexDateFormats: {
    precisionYear: 'yyyy',
    precisionMonth: 'MMMM yyyy',
    precisionDay: 'MMMM d, yyyy',
    precisionTime: "MMMM d, yyyy, h':'mm aaa", // eslint-disable-line quotes
  },
  colors: {
    blueExtraLight: '#ecf4f9',
    bluePowder: '#67a3cb',
    blueMedium: '#255677',
    blueSteel: '#1a3d55',
    grayExtraLight: '#f7f7f7',
    grayLight: '#e3e3e3',
    grayMedium: '#a1a1a1',
    grayDark: '#333',
    borderDark: '#a6a6a6',
    black: '#000',
    success: '#00a225',
    warning: '#ffbe43',
    error: '#d60000',
  },
  customTitleFieldTypes: {
    text: 'text',
    multilineText: 'multiline_text',
    slug: 'slug',
    integer: 'integer',
    float: 'float',
    datetime: 'datetime',
    bool: 'bool',
    choice: 'choice',
    color: 'color',
    table: 'table',
    image: 'image',
    file: 'file',
    media: 'media',
    title: 'title',
    object: 'object',
  },
  previewImageVersions: [
    { label: 'Poster Frame', slug: 'poster-frame' },
    { label: 'Vertical', slug: 'vertical' },
    { label: 'Square', slug: 'square' },
    { label: 'Horizontal Thumbnail', slug: 'horizontal-thumbnail' },
    { label: 'Square Thumbnail', slug: 'square-thumbnail' },
  ],
  csrfCookieName: 'csrftoken',
  // TODO - configure urls dynamically
  urls: {
    organizationSettings: '/settings/organization/',
    integrationSettings: '/settings/integrations/',
    templatePresetSettings: '/settings/template/',
    asyncJobDetailBase: '/async/job/',
    previewTemplateChoices: '/preview/templates/',
    mediaBase: '/media/',
    mediaData: '/media/list/',
    mediaDataBase: '/media/data/',
    mediaUpload: '/media/upload/',
    mediaUploadChunked: '/media/upload/chunked/',
    mediaUploadChunkedComplete: '/media/upload/chunked/complete/',
    mediaPreviewBase: '/media/preview/',
    mediaPreviewImagesBase: '/media/preview-images/',
    mediaImportUrl: '/media/import/url/',
    vimeoImport: '/vimeo/import/',
    vimeoImportBase: '/vimeo/import/',
    vimeoListBase: '/vimeo/list/',
    vimeoAccountList: '/vimeo/accounts/',
    vimeoConnect: '/vimeo/connect/',
    vimeoUpdateTextTracksBase: '/vimeo/update_text_tracks/',
    youtubeImport: '/youtube/import/',
    // TODO
    // youtubeImportBase: '/youtube/import/',
    // youtubeListBase: '/youtube/list/',
    // youtubeAccountList: '/youtube/accounts/',
    // youtubeConnect: '/youtube/connect/',
    facebookConnect: '/facebook/connect/',
    facebookLinkPreview: '/facebook/og_preview/',
    titleBase: '/titles/',
    titleData: '/titles/list/',
    titleTypes: '/titles/types/',
    titleDataBase: '/titles/data/',
    titleCreate: '/titles/new/',
    titlePreviewBase: '/titles/preview/',
    releaseBase: '/releases/',
    releaseData: '/releases/list/',
    releaseTypes: '/releases/types/',
    releaseCreate: '/releases/new/',
    releaseContentDataBase: '/releases/content-data/',
    releasePublishBase: '/releases/publish/',
    releasePublishTaskStatusBase: '/releases/publish-task-status/',
    releasePublishStatusBase: '/releases/publish-status/',
    releasePublishHistoryBase: '/releases/publish-history/',
    releaseTemplateChoicesBase: '/releases/templates/',
    releaseTemplateOptionsBase: '/releases/template-options/',
    contributors: '/contributors/',
    contributorBase: '/contributors/',
    contributorData: '/contributors/list/',
    contributorChoices: '/contributors/choices/',
    contributorCreate: '/contributors/create/',
    contributorDetailBase: '/contributors/detail/',
    contributorPreviewBase: '/contributors/preview/',
    tagBase: '/tags/',
    tagData: '/tags/list/',
    tagListCreate: '/tags/',
    metadataPresetBase: '/metadata-presets/',
    metadataPresetManage: '/metadata-presets/manage/',
    metadataPresetData: '/metadata-presets/list/',
    metadataPresetDataBase: '/metadata-presets/data/',
    metadataPresetCreate: '/metadata-presets/new/',
    presetsBase: '/presets/',
    presets: '/presets/',
    presetDuplicateBase: '/presets/duplicate/',
    presetTemplateOptionsBase: '/presets/template-options/',
    presetPreviewBase: '/presets/preview/',
    integrationList: '/integrations/list/',
    integrationBase: '/integrations/',
    integrationEditBase: '/integrations/edit/',
    integrationDeleteBase: '/integrations/delete/',
    s3Create: '/s3/new/',
    podcastCreate: '/podcast/new/',
    podcastImportBase: '/podcast/import/',
    podcastListBase: '/podcast/list/',
    podcastFeedList: '/podcast/feeds/',
    mapboxCreate: '/mapbox/new/',
    mapboxStylesBase: '/mapbox/styles/',
    netlifyConnect: '/netlify/connect/',
    typekitKitBase: '/typekit/kit/',
    sampleDataImport: '/clone/sample-data/',
    // actions
    metadataImport: '/actions/metadata-import/',
    releaseBulkPublish: '/actions/releases/publish/',
    releaseConfigureAnalytics: '/actions/releases/configure-analytics/',
  },
  webSafeFontFamilies: [
    { family: 'Arial', fallbacks: 'Helvetica, sans-serif', scaleFactor: 1.05 },
    { family: 'Georgia', fallbacks: 'serif', scaleFactor: 1.15 },
    { family: 'Helvetica', fallbacks: '"Helvetica Neue", Arial, sans-serif', scaleFactor: 1.05 },
    { family: 'Palatino', fallbacks: 'Georgia, serif', scaleFactor: 1.3 },
    { family: 'Tahoma', fallbacks: 'Helvetica, Arial, sans-serif', scaleFactor: 0.95 },
    { family: 'Times New Roman', fallbacks: 'Georgia, serif', scaleFactor: 1.2 },
    { family: 'Trebuchet MS', fallbacks: 'Helvetica, Arial, sans-serif', scaleFactor: 1.05 },
    { family: 'Verdana', fallbacks: 'Helvetica, Arial, sans-serif', scaleFactor: 0.9 },
  ],
  helpUrls: {
    // Zendesk
    websiteReleaseAWSConfig: 'https://mediastorm.zendesk.com/hc/en-us/articles/360044403353-AWS-Configuration-for-Website-Releases',

    // External
    netlifyApexDomainConfig: 'https://docs.netlify.com/domains-https/custom-domains/configure-external-dns/#configure-an-apex-domain',
  },
  countries: [
    ['AF', 'Afghanistan'],
    ['AX', 'Åland Islands'],
    ['AL', 'Albania'],
    ['DZ', 'Algeria'],
    ['AS', 'American Samoa'],
    ['AD', 'Andorra'],
    ['AO', 'Angola'],
    ['AI', 'Anguilla'],
    ['AQ', 'Antarctica'],
    ['AG', 'Antigua and Barbuda'],
    ['AR', 'Argentina'],
    ['AM', 'Armenia'],
    ['AW', 'Aruba'],
    ['AU', 'Australia'],
    ['AT', 'Austria'],
    ['AZ', 'Azerbaijan'],
    ['BS', 'Bahamas'],
    ['BH', 'Bahrain'],
    ['BD', 'Bangladesh'],
    ['BB', 'Barbados'],
    ['BY', 'Belarus'],
    ['BE', 'Belgium'],
    ['BZ', 'Belize'],
    ['BJ', 'Benin'],
    ['BM', 'Bermuda'],
    ['BT', 'Bhutan'],
    ['BO', 'Bolivia'],
    ['BQ', 'Bonaire, Sint Eustatius and Saba'],
    ['BA', 'Bosnia and Herzegovina'],
    ['BW', 'Botswana'],
    ['BV', 'Bouvet Island'],
    ['BR', 'Brazil'],
    ['IO', 'British Indian Ocean Territory'],
    ['BN', 'Brunei'],
    ['BG', 'Bulgaria'],
    ['BF', 'Burkina Faso'],
    ['BI', 'Burundi'],
    ['CV', 'Cabo Verde'],
    ['KH', 'Cambodia'],
    ['CM', 'Cameroon'],
    ['CA', 'Canada'],
    ['KY', 'Cayman Islands'],
    ['CF', 'Central African Republic'],
    ['TD', 'Chad'],
    ['CL', 'Chile'],
    ['CN', 'China'],
    ['CX', 'Christmas Island'],
    ['CC', 'Cocos (Keeling) Islands'],
    ['CO', 'Colombia'],
    ['KM', 'Comoros'],
    ['CG', 'Congo'],
    ['CD', 'Congo (the Democratic Republic of the)'],
    ['CK', 'Cook Islands'],
    ['CR', 'Costa Rica'],
    ['CI', 'Côte d’Ivoire'],
    ['HR', 'Croatia'],
    ['CU', 'Cuba'],
    ['CW', 'Curaçao'],
    ['CY', 'Cyprus'],
    ['CZ', 'Czechia'],
    ['DK', 'Denmark'],
    ['DJ', 'Djibouti'],
    ['DM', 'Dominica'],
    ['DO', 'Dominican Republic'],
    ['EC', 'Ecuador'],
    ['EG', 'Egypt'],
    ['SV', 'El Salvador'],
    ['GQ', 'Equatorial Guinea'],
    ['ER', 'Eritrea'],
    ['EE', 'Estonia'],
    ['ET', 'Ethiopia'],
    ['FK', 'Falkland Islands  [Malvinas]'],
    ['FO', 'Faroe Islands'],
    ['FJ', 'Fiji'],
    ['FI', 'Finland'],
    ['FR', 'France'],
    ['GF', 'French Guiana'],
    ['PF', 'French Polynesia'],
    ['TF', 'French Southern Territories'],
    ['GA', 'Gabon'],
    ['GM', 'Gambia'],
    ['GE', 'Georgia'],
    ['DE', 'Germany'],
    ['GH', 'Ghana'],
    ['GI', 'Gibraltar'],
    ['GR', 'Greece'],
    ['GL', 'Greenland'],
    ['GD', 'Grenada'],
    ['GP', 'Guadeloupe'],
    ['GU', 'Guam'],
    ['GT', 'Guatemala'],
    ['GG', 'Guernsey'],
    ['GN', 'Guinea'],
    ['GW', 'Guinea-Bissau'],
    ['GY', 'Guyana'],
    ['HT', 'Haiti'],
    ['HM', 'Heard Island and McDonald Islands'],
    ['VA', 'Holy See'],
    ['HN', 'Honduras'],
    ['HK', 'Hong Kong'],
    ['HU', 'Hungary'],
    ['IS', 'Iceland'],
    ['IN', 'India'],
    ['ID', 'Indonesia'],
    ['IR', 'Iran'],
    ['IQ', 'Iraq'],
    ['IE', 'Ireland'],
    ['IM', 'Isle of Man'],
    ['IL', 'Israel'],
    ['IT', 'Italy'],
    ['JM', 'Jamaica'],
    ['JP', 'Japan'],
    ['JE', 'Jersey'],
    ['JO', 'Jordan'],
    ['KZ', 'Kazakhstan'],
    ['KE', 'Kenya'],
    ['KI', 'Kiribati'],
    ['KW', 'Kuwait'],
    ['KG', 'Kyrgyzstan'],
    ['LA', 'Laos'],
    ['LV', 'Latvia'],
    ['LB', 'Lebanon'],
    ['LS', 'Lesotho'],
    ['LR', 'Liberia'],
    ['LY', 'Libya'],
    ['LI', 'Liechtenstein'],
    ['LT', 'Lithuania'],
    ['LU', 'Luxembourg'],
    ['MO', 'Macao'],
    ['MK', 'Macedonia'],
    ['MG', 'Madagascar'],
    ['MW', 'Malawi'],
    ['MY', 'Malaysia'],
    ['MV', 'Maldives'],
    ['ML', 'Mali'],
    ['MT', 'Malta'],
    ['MH', 'Marshall Islands'],
    ['MQ', 'Martinique'],
    ['MR', 'Mauritania'],
    ['MU', 'Mauritius'],
    ['YT', 'Mayotte'],
    ['MX', 'Mexico'],
    ['FM', 'Micronesia (Federated States of)'],
    ['MD', 'Moldova'],
    ['MC', 'Monaco'],
    ['MN', 'Mongolia'],
    ['ME', 'Montenegro'],
    ['MS', 'Montserrat'],
    ['MA', 'Morocco'],
    ['MZ', 'Mozambique'],
    ['MM', 'Myanmar'],
    ['NA', 'Namibia'],
    ['NR', 'Nauru'],
    ['NP', 'Nepal'],
    ['NL', 'Netherlands'],
    ['NC', 'New Caledonia'],
    ['NZ', 'New Zealand'],
    ['NI', 'Nicaragua'],
    ['NE', 'Niger'],
    ['NG', 'Nigeria'],
    ['NU', 'Niue'],
    ['NF', 'Norfolk Island'],
    ['KP', 'North Korea'],
    ['MP', 'Northern Mariana Islands'],
    ['NO', 'Norway'],
    ['OM', 'Oman'],
    ['PK', 'Pakistan'],
    ['PW', 'Palau'],
    ['PS', 'Palestine, State of'],
    ['PA', 'Panama'],
    ['PG', 'Papua New Guinea'],
    ['PY', 'Paraguay'],
    ['PE', 'Peru'],
    ['PH', 'Philippines'],
    ['PN', 'Pitcairn'],
    ['PL', 'Poland'],
    ['PT', 'Portugal'],
    ['PR', 'Puerto Rico'],
    ['QA', 'Qatar'],
    ['RE', 'Réunion'],
    ['RO', 'Romania'],
    ['RU', 'Russia'],
    ['RW', 'Rwanda'],
    ['BL', 'Saint Barthélemy'],
    ['SH', 'Saint Helena, Ascension and Tristan da Cunha'],
    ['KN', 'Saint Kitts and Nevis'],
    ['LC', 'Saint Lucia'],
    ['MF', 'Saint Martin (French part)'],
    ['PM', 'Saint Pierre and Miquelon'],
    ['VC', 'Saint Vincent and the Grenadines'],
    ['WS', 'Samoa'],
    ['SM', 'San Marino'],
    ['ST', 'Sao Tome and Principe'],
    ['SA', 'Saudi Arabia'],
    ['SN', 'Senegal'],
    ['RS', 'Serbia'],
    ['SC', 'Seychelles'],
    ['SL', 'Sierra Leone'],
    ['SG', 'Singapore'],
    ['SX', 'Sint Maarten (Dutch part)'],
    ['SK', 'Slovakia'],
    ['SI', 'Slovenia'],
    ['SB', 'Solomon Islands'],
    ['SO', 'Somalia'],
    ['ZA', 'South Africa'],
    ['GS', 'South Georgia and the South Sandwich Islands'],
    ['KR', 'South Korea'],
    ['SS', 'South Sudan'],
    ['ES', 'Spain'],
    ['LK', 'Sri Lanka'],
    ['SD', 'Sudan'],
    ['SR', 'Suriname'],
    ['SJ', 'Svalbard and Jan Mayen'],
    ['SZ', 'Swaziland'],
    ['SE', 'Sweden'],
    ['CH', 'Switzerland'],
    ['SY', 'Syria'],
    ['TW', 'Taiwan'],
    ['TJ', 'Tajikistan'],
    ['TZ', 'Tanzania'],
    ['TH', 'Thailand'],
    ['TL', 'Timor-Leste'],
    ['TG', 'Togo'],
    ['TK', 'Tokelau'],
    ['TO', 'Tonga'],
    ['TT', 'Trinidad and Tobago'],
    ['TN', 'Tunisia'],
    ['TR', 'Turkey'],
    ['TM', 'Turkmenistan'],
    ['TC', 'Turks and Caicos Islands'],
    ['TV', 'Tuvalu'],
    ['UG', 'Uganda'],
    ['UA', 'Ukraine'],
    ['AE', 'United Arab Emirates'],
    ['GB', 'United Kingdom'],
    ['UM', 'United States Minor Outlying Islands'],
    ['US', 'United States of America'],
    ['UY', 'Uruguay'],
    ['UZ', 'Uzbekistan'],
    ['VU', 'Vanuatu'],
    ['VE', 'Venezuela'],
    ['VN', 'Vietnam'],
    ['VG', 'Virgin Islands (British)'],
    ['VI', 'Virgin Islands (U.S.)'],
    ['WF', 'Wallis and Futuna'],
    ['EH', 'Western Sahara'],
    ['YE', 'Yemen'],
    ['ZM', 'Zambia'],
    ['ZW', 'Zimbabwe'],
  ],
});
