import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid4 } from 'uuid';
import { previewImageVersions } from 'app-constants';
import renderBlockErrors from './renderBlockErrors';
import AddLibraryMedia from 'components/common/AddLibraryMedia';
import Select from 'components/common/Select';
import ImageVersionSelect from 'components/common/ImageVersionSelect';
import SortableItem from 'components/common/SortableItem';
import ImageGridItem from './ImageGridItem';
import ImageGridItemForm from './ImageGridItemForm';

const ITEM_DEFAULTS = {
  label: '',
  labelSource: 'custom',
  linkUrl: '',
  description: '',
  descriptionSource: 'none',
};

const ImageGrid = ({ uid, errors, items, size, imageSize, imageVersion, isExpanded, onPreviewTextUpdate, onChange }) => {
  const countText = `${items.length} image${items.length !== 1 ? 's' : ''}`;

  useEffect(() => onPreviewTextUpdate(countText), [items.length]);

  const handleFieldChange = (name, val) => onChange({ [name]: val });

  const sizeOptions = [
    { value: 'small', label: 'Small' },
    { value: 'medium', label: 'Medium' },
    { value: 'large', label: 'Large' },
    { value: 'full', label: 'Full width' },
  ];
  const sizeSelectVal = sizeOptions.find(opt => opt.value === size);

  const imageSizeOptions = [
    { value: 'small', label: 'Small' },
    { value: 'medium', label: 'Medium' },
    { value: 'large', label: 'Large' },
  ];
  const imageSizeSelectVal = imageSizeOptions.find(opt => opt.value === imageSize);

  const [activeItemId, setActiveItemId] = useState(null);
  const activeItem = items.find(item => item.uid === activeItemId);

  const { items: itemErrors, ...blockErrors } = errors || {};
  const renderedErrors = renderBlockErrors(blockErrors);
  const renderedItemErrors = itemErrors ? renderBlockErrors(itemErrors[activeItemId] || {}) : {};

  useEffect(() => {
    if (!isExpanded) setActiveItemId(null);
  }, [isExpanded]);

  useEffect(() => {
    // unset activeItemId if the associated item is deleted
    if (activeItemId && !items.find(item => item.uid === activeItemId)) {
      setActiveItemId(null);
    }
  }, [items]);

  const handleItemFormChange = changedVals => {
    if (!activeItemId) return null;
    const newItems = items.map(item => {
      if (item.uid === activeItemId) {
        return { ...item, ...changedVals };
      } else {
        return item;
      }
    });
    onChange({ items: newItems });
  };

  const handleItemClick = uid => setActiveItemId(uid === activeItemId ? null : uid);

  const handleItemDelete = uid => {
    onChange({ items: items.filter(item => item.uid !== uid) });
  };

  const [cachedMediaData, setCachedMediaData] = useState({});
  const handleItemMediaLoad = (uid, data) => setCachedMediaData(oldState => ({ ...oldState, [uid]: data }));

  const handleMediaAdd = mediaIds => {
    if (!mediaIds.length) return null;
    const newItems = mediaIds.map(mediaId => ({
      ...ITEM_DEFAULTS,
      uid: uuid4(),
      media: mediaId,
    }));
    onChange({ items: [...items, ...newItems] });
    setActiveItemId(newItems[0].uid);
  };

  // drag and drop sorting
  const handleItemMove = useCallback((sourceId, targetId, insertPos) => {
    const souceItem = items.find(item => item.uid === sourceId);
    const targetIndex = items.findIndex(item => item.uid === targetId);
    const toIndex = insertPos === 'before' ? targetIndex : targetIndex + 1;
    const newItems = items.filter(item => item.uid !== sourceId);
    newItems.splice(toIndex, 0, souceItem);
    onChange({ items: newItems });
  }, [items, onChange]);

  return (
    <>
      <div className="content">
        <h6>Block Settings</h6>
        <div className="mb-3">
          <div>Size:</div>
          <div className="mb-1">
            <Select
              value={sizeSelectVal}
              options={sizeOptions}
              onChange={val => handleFieldChange('size', val)}
            />
            {renderedErrors.size}
            <div className="text-hint mt-1">
              This field controls the overall size of the image grid.
            </div>
          </div>
        </div>

        <div className="mb-3">
          <div>Image Size:</div>
          <div className="mb-1">
            <Select
              value={imageSizeSelectVal}
              options={imageSizeOptions}
              onChange={val => handleFieldChange('imageSize', val)}
            />
            {renderedErrors.imageSize}
            <div className="text-hint mt-1">
              This field controls the size of individual images within the grid.
            </div>
          </div>
        </div>

        <div className="mb-3">
          <div>Image Version:</div>
          <div className="mb-1">
            <ImageVersionSelect
              value={imageVersion}
              onChange={val => handleFieldChange('imageVersion', val)}
            />
            {renderedErrors.imageVersion}
            <div className="text-hint mt-1">
              Select which image version to display.
            </div>
          </div>
        </div>
      </div>

      {items.length > 0 && (
        <div className="content">
          <div className="sc-image-grid">
            {items.map(({ uid: itemId, media, label, labelSource }, idx) => (
              <SortableItem
                key={itemId}
                itemId={itemId}
                type={`sc-image-grid-${uid}`}
                onItemMove={handleItemMove}
              >
                {(connectDragSource) => (
                  <ImageGridItem
                    connectDragSource={connectDragSource}
                    media={media}
                    label={label}
                    labelSource={labelSource}
                    counter={idx + 1}
                    active={itemId === activeItemId}
                    error={!!errors.items && !!errors.items[itemId]}
                    shouldDelete={false}
                    onClick={() => handleItemClick(itemId)}
                    onDeleteClick={() => handleItemDelete(itemId)}
                    onMediaLoad={data => handleItemMediaLoad(itemId, data)}
                  />
                )}
              </SortableItem>
            ))}
          </div>
        </div>
      )}
      <div className="content">
        <div className="flex-spread">
          <div className="text-meta">{countText}</div>
          <AddLibraryMedia
            allowMultiple
            preFilter={{ type: 'image' }}
            disabledFilters={['type', 'source', 'duration']}
            onChange={ids => handleMediaAdd(ids)}
          >
            {handleModalTrigger => (
              <button type="button" className="btn btn-primary btn-sm" onClick={handleModalTrigger}>Add Images</button>
            )}
          </AddLibraryMedia>
        </div>
      </div>
      {items.length > 0 && (
        <ImageGridItemForm
          item={activeItem}
          mediaData={cachedMediaData[activeItemId]}
          errors={renderedItemErrors}
          onChange={handleItemFormChange}
        />
      )}
    </>
  );
};

ImageGrid.propTypes = {
  uid: PropTypes.string,
  errors: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.shape({
    uid: PropTypes.string,
    media: PropTypes.string,
    label: PropTypes.string,
    labelSource: PropTypes.oneOf(['media_name', 'custom', 'none']),
    description: PropTypes.string,
    descriptionSource: PropTypes.oneOf(['media_long_desc', 'media_short_desc', 'custom', 'none']),
    linkUrl: PropTypes.string,
  })),
  size: PropTypes.oneOf(['small', 'medium', 'large', 'full']),
  imageSize: PropTypes.oneOf(['small', 'medium', 'large']),
  imageVersion: PropTypes.oneOf(['original', ...previewImageVersions.map(({ slug }) => slug)]),
  isExpanded: PropTypes.bool,
  onPreviewTextUpdate: PropTypes.func,
  onChange: PropTypes.func,
};

export default ImageGrid;
