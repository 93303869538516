import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import snakeize from 'snakeize';

const PrerollOptions = ({ field }) => {
  const [fields, setFields] = useState({});
  useEffect(() => {
    const vals = camelize(JSON.parse(field.value));
    setFields(vals);
    setShowCustomOverlayInput(!!vals.customOverlayText);
  }, [field.value]);

  const {
    countdownOverlay,
    countdownLabel,
    customOverlayText,
    skipOverlay,
  } = fields;

  const updateField = useCallback((name, value) => {
    setFields(oldState => ({ ...oldState, [name]: value }));
  }, [setFields]);

  const handleInputChange = evt => {
    let { name, value, type } = evt.target;
    if (type === 'checkbox') {
      value = fields[name] === null ? true : !fields[name];
    }
    updateField(name, value);
  };

  const [showCustomOverlayInput, setShowCustomOverlayInput] = useState(false);

  const handleShowCustomOverlayChange = evt => {
    const val = evt.target.checked;
    setShowCustomOverlayInput(val);
    if (!val) updateField('customOverlayText', '');
  };

  const inputVal = JSON.stringify(snakeize(fields));

  return (
    <div>
      <label className="form-checkbox">
        <input
          type="checkbox"
          name="countdownOverlay"
          checked={!!countdownOverlay}
          onChange={handleInputChange}
        />
        <i className="form-icon" /> Countdown timer
      </label>

      {!!countdownOverlay && (
        <div className="form-group">
          <input
            name="countdownLabel"
            type="text"
            className="form-input"
            onChange={handleInputChange}
            value={countdownLabel || ''}
          />
          <div className="text-hint mt-1">Optionally include a text label before countdown timer.</div>
        </div>
      )}

      <label className="form-checkbox">
        <input
          type="checkbox"
          name="skipOverlay"
          checked={!!skipOverlay}
          onChange={handleInputChange}
        />
        <i className="form-icon" /> Skip button
      </label>

      <label className="form-checkbox">
        <input
          type="checkbox"
          checked={showCustomOverlayInput}
          onChange={handleShowCustomOverlayChange}
        />
        <i className="form-icon" /> Custom text
      </label>

      {showCustomOverlayInput && (
        <input
          name="customOverlayText"
          type="text"
          className="form-input"
          onChange={handleInputChange}
          value={customOverlayText || ''}
        />
      )}

      <input type="hidden" name={field.name} value={inputVal} />
    </div>
  );
};

PrerollOptions.propTypes = {
  field: PropTypes.object,
};

export default PrerollOptions;
