import React from 'react';
import PropTypes from 'prop-types';
import CustomTitleField from 'components/views/CustomTitleBuilder/CustomTitleField';
import VisibilityKey from 'components/common/VisibilityKey';
import getDisplayOptionsConfig from './displayOptionsConfig';
import objectTypes from './objectTypes';
import MenuItems from './MenuItems';

const DisplayOptions = ({ config, parentType, itemType, value, onChange }) => {
  const handleFieldChange = (name, val) => onChange({
    ...value,
    [name]: val,
  });

  let { fields, menuItems } = config || getDisplayOptionsConfig(parentType, itemType);
  if (!window.IS_STAFF_USER) {
    fields = fields.filter(f => !f.restricted);
  }

  return !!value && (
    <>
      {fields.map(({ name, ...conf }) => (
        <CustomTitleField
          key={name}
          {...conf}
          value={value[name]}
          onChange={val => handleFieldChange(name, val)}
        />
      ))}

      {menuItems.length > 0 && (
        <div className="form-group mb-3">
          <label className="block-label">Menu Items</label>
          <div className="text-hint mb-2">Use the form below to toggle the visibility of individual menu items.</div>
          <VisibilityKey />
          <MenuItems
            parentType={parentType}
            itemType={itemType}
            value={value.menu_items}
            onChange={val => handleFieldChange('menu_items', val)}
          />
        </div>
      )}
    </>
  );
};

DisplayOptions.propTypes = {
  config: PropTypes.shape({
    fields: PropTypes.array,
    menuItems: PropTypes.array,
  }),
  parentType: PropTypes.oneOf(objectTypes),
  itemType: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default DisplayOptions;
