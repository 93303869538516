import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MediaImportModal from './MediaImportModal';

const MediaImport = ({ workspaceAdmin = false }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const hideModal = () => setModalVisible(false);

  const handleAddMediaClick = evt => {
    evt.preventDefault();
    setModalVisible(true);
  };

  const handleImportComplete = () => {
    hideModal();
    window.location.reload();
  };

  return (
    <div>
      <a href="#add-media" className="btn btn-primary" onClick={handleAddMediaClick}>Add Media</a>
      <MediaImportModal
        workspaceAdmin={workspaceAdmin}
        isOpen={modalVisible}
        onRequestClose={hideModal}
        onImportComplete={handleImportComplete}
      />
    </div>
  );
};

MediaImport.propTypes = {
  workspaceAdmin: PropTypes.bool,
};

export default MediaImport;
