import React, { Component } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import { v4 as uuid4 } from 'uuid';
import { formatters } from 'utils';
import { urls } from 'app-constants';
import withCSRF from 'components/common/withCSRF';
import DragDropFileInput from 'components/common/DragDropFileInput';
import Icon from 'components/common/Icon';
import LoadingSpinner from 'components/common/LoadingSpinner';


const STATUS_PENDING = 'pending';
const STATUS_UPLOADING = 'uploading';
const STATUS_COMPLETE = 'complete';
const STATUS_FAILED = 'failed';

class FileUpload extends Component {
  constructor (props) {
    super(props);
    this.state = {
      files: [],
      rejectedFiles: [],
      uploading: false,
      uploadComplete: false,
      uploadError: null,
      totalBytes: 0,
      completedBytes: 0,
      fileProgress: 0,
      fileListMaxHeight: 500,
    };

    this.chunkedFileSize = 1000 * 1024; // files larger than 1 MB are uploaded in chunks
    this.chunkSize = 1000 * 512;

    this.handleFileDrop = this.handleFileDrop.bind(this);
    this.handleRemoveFile = this.handleRemoveFile.bind(this);
    this.handleUploadStart = this.handleUploadStart.bind(this);
    this.handleUploadProgress = this.handleUploadProgress.bind(this);
    this.handleUploadComplete = this.handleUploadComplete.bind(this);
    this.handleUploadError = this.handleUploadError.bind(this);
    this.handleUploadCancel = this.handleUploadCancel.bind(this);
    this.doUpload = this.doUpload.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.uploadFileChunked = this.uploadFileChunked.bind(this);
    this.completeChunkedUpload = this.completeChunkedUpload.bind(this);
    this.setFileListHeight = this.setFileListHeight.bind(this);
  }

  componentDidUpdate (prevProps, prevState) {
    const { files } = this.state;

    if (files !== prevState.files) {
      this.setState({
        totalBytes: files.reduce((result, f) => result + f.file.size, 0),
      });
      this.setFileListHeight();
      window.addEventListener('resize', this.setFileListHeight);
    }
  }

  componentWillUnmount () {
    this.handleUploadCancel();
    window.removeEventListener('resize', this.setFileListHeight);
  }

  handleFileDrop (accepted, rejected) {
    const { files } = this.state;
    this.setState({ files: [...files, ...accepted.map(file => ({ uid: uuid4(), status: STATUS_PENDING, file }))] });
  }

  handleRemoveFile (idx) {
    const { files } = this.state;
    this.setState({ files: files.filter((f, i) => i !== idx) });
  }

  handleUploadStart () {
    const { files } = this.state;
    this.setState({ uploading: true }, this.doUpload(files.find(curFile => curFile.status === STATUS_PENDING)));
  }

  handleUploadProgress (e) {
    this.setState({ fileProgress: e.loaded });
  }

  handleFileComplete (f, e) {
    const { files, completedBytes } = this.state;
    const { status, statusText, responseText } = e.target;
    if (status !== 200) {
      this.setState({
        uploading: false,
        completedBytes: 0,
        uploadError: statusText,
        files: files.map(curFile => {
          if (curFile.uid === f.uid) {
            return { ...curFile, xhr: null, status: STATUS_FAILED };
          } else {
            return curFile;
          }
        }),
      });
    } else {
      // Update status
      const data = JSON.parse(responseText);
      let errorMessage = null;
      let status = STATUS_COMPLETE;
      if (data.failed.length > 0) {
        status = STATUS_FAILED;
        errorMessage = data.failed[0].detail;
      }
      this.setState({
        completedBytes: completedBytes + f.file.size,
        files: files.map(curFile => {
          if (curFile.uid === f.uid) {
            return { ...curFile, xhr: null, status, errorMessage };
          } else {
            return curFile;
          }
        }),
      }, () => {
        // Process next file
        const nextFile = files.find(curFile => curFile.status === STATUS_PENDING);
        if (nextFile) {
          this.doUpload(nextFile);
        } else {
          this.handleUploadComplete();
        }
      });
    }
  }

  handleUploadComplete () {
    const { instanceId, onComplete } = this.props;
    if (instanceId && onComplete) {
      onComplete();
    } else {
      this.setState({
        uploading: false,
        uploadComplete: true,
        completedBytes: 0,
      });
    }
  }

  handleUploadError (msg) {
    this.setState({
      uploading: false,
      completedBytes: 0,
      uploadError: msg,
    });
  }

  handleUploadCancel () {
    const files = [...this.state.files];
    files.forEach(f => {
      if (f.xhr) {
        f.xhr.abort();
        f.xhr = null;
      }
      if ([STATUS_PENDING, STATUS_UPLOADING].includes(f.status)) {
        f.status = STATUS_FAILED;
        f.errorMessage = 'Upload canceled';
      }
    });
    this.setState({
      files,
      uploading: false,
      uploadComplete: true,
      completedBytes: 0,
    });
  }


  uploadFile (f) {
    const { csrfToken, instanceId } = this.props;
    const formData = new FormData();
    formData.append('files', f.file);

    let url = urls.mediaUpload;
    if (instanceId) url += `?instance=${instanceId}`;

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.withCredentials = true;
    xhr.setRequestHeader('X-CSRFToken', csrfToken);
    xhr.onload = e => this.handleFileComplete(f, e);
    xhr.onerror = e => this.handleUploadError('Network error');
    xhr.upload.onprogress = this.handleUploadProgress;
    xhr.send(formData);

    return xhr;
  }

  completeChunkedUpload (f, uploadId) {
    const { csrfToken, instanceId } = this.props;
    const formData = new FormData();
    formData.append('uid', uploadId);
    let url = urls.mediaUploadChunkedComplete;
    if (instanceId) url += `?instance=${instanceId}`;

    fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'X-CSRFToken': csrfToken,
      },
      body: formData,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response;
      })
      .then(response => {
        response.text().then(responseText => {
          this.handleFileComplete(f, {
            target: {
              status: response.status,
              statusText: response.statusText,
              responseText,
            },
          });
        });
      })
      .catch(err => {
        console.error(err);
        this.handleUploadError('Network error');
      });
  }

  uploadFileChunked (f) {
    const { csrfToken } = this.props;

    const uploadChunk = (start = 0, uploadId = null) => {
      const end = start + this.chunkSize;
      const blob = f.file.slice(start, end);
      const formData = new FormData();
      formData.append('file', blob, f.file.name);
      formData.append('offset', start);
      if (uploadId) {
        formData.append('uid', uploadId);
      }

      fetch(urls.mediaUploadChunked, {
        credentials: 'include',
        method: 'POST',
        headers: {
          'X-CSRFToken': csrfToken,
        },
        body: formData,
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response;
        })
        .then(response => response.json())
        .then(data => camelize(data))
        .then(data => {
          const { uid, offset } = data;
          const { uploading } = this.state;
          if (uploading) {
            this.setState({ fileProgress: offset }, () => {
              if (offset < f.file.size) {
                uploadChunk(offset, uid);
              } else {
                this.completeChunkedUpload(f, uid);
              }
            });
          }
        })
        .catch(err => {
          console.error(err);
          this.handleUploadError('Network error');
        });
    };

    uploadChunk();

    return null;
  }

  doUpload (f) {
    const { files } = this.state;

    const xhr = f.file.size < this.chunkedFileSize ? this.uploadFile(f) : this.uploadFileChunked(f);

    this.setState({
      files: files.map(curFile => {
        if (curFile.uid === f.uid) {
          return { ...curFile, status: STATUS_UPLOADING, xhr };
        } else {
          return curFile;
        }
      }),
    });
  }

  setFileListHeight () {
    const fileList = this.fileListRef;
    if (fileList) {
      const maxHeight = window.innerHeight - this.fileListRef.getBoundingClientRect().top - 150;
      this.setState({ fileListMaxHeight: maxHeight });
    }
  }

  renderFileInput () {
    const { instanceId } = this.props;
    const { files } = this.state;

    return (
      <div className="mb-4">
        <DragDropFileInput
          helpText={instanceId ? undefined : 'Drag files here to queue for upload'}
          onFileDrop={this.handleFileDrop}
          allowedTypes={instanceId ? ['audio'] : ['image', 'audio']}
          multiple={!instanceId}
          disabled={!!instanceId && files.length === 1}
        />
      </div>
    );
  }

  renderProgressBar () {
    const { completedBytes, fileProgress, totalBytes } = this.state;
    const progressPercent = (completedBytes + fileProgress) / totalBytes * 100;

    return (
      <div className="progress media-import-uploader-progress">
        <div className="progress-bar" style={{ width: `${progressPercent}%` }} />
      </div>
    );
  }

  renderCompleteMessage () {
    const { onComplete } = this.props;
    const { files } = this.state;
    const result = { successItems: [], failureItems: [] };
    const { successItems, failureItems } = files.reduce((result, f) => {
      if (f.status === STATUS_COMPLETE) {
        result.successItems.push(f.file.name);
      } else {
        result.failureItems.push(`${f.file.name} (${f.errorMessage})`);
      }
      return result;
    }, result);

    return (
      <div className="mb-4">
        {successItems.length > 0 && (
          <div className="success-list mb-3">
            <p><strong>{successItems.length} {successItems.length === 1 ? 'file was' : 'files were'} imported successfully:</strong></p>
            <ul>{successItems.map(name => <li key={name}>{name}</li>)}</ul>
          </div>
        )}
        {failureItems.length > 0 && (
          <div className="error-list mb-3">
            <p><strong>{failureItems.length} {failureItems.length === 1 ? 'file' : 'files'} failed to import:</strong></p>
            <ul>{failureItems.map(name => <li key={name}>{name}</li>)}</ul>
          </div>
        )}
        {successItems.length > 0 && onComplete && (
          <div className="d-flex align-items-center justify-content-center bg-gray-light p-4">
            <button className="btn btn-primary btn-lg" type="button" onClick={onComplete}>View Imported Items</button>
          </div>
        )}
      </div>
    );
  }

  renderActionBar () {
    const { files, uploading, totalBytes } = this.state;
    const { bytes } = formatters;

    return (
      <div className="media-import-actions">
        <div className="count">
          {files.length} File{files.length === 1 ? '' : 's'} Selected
          {totalBytes > 0 ? ` (${bytes(totalBytes)})` : null}
        </div>
        {uploading ? (
          <button
            key="cancel-upload"
            className="btn btn-error"
            onClick={this.handleUploadCancel}
          >Cancel Upload
          </button>
        ) : (
          <button
            key="start-upload"
            className="btn btn-primary"
            disabled={files.length === 0 || uploading}
            onClick={this.handleUploadStart}
          >Start Upload
          </button>
        )}
      </div>
    );
  }

  renderFileList () {
    const { files, fileListMaxHeight } = this.state;
    const { bytes } = formatters;

    if (files.length === 0) {
      return null;
    }

    return (
      <ul className="media-import-uploader-file-list" ref={node => this.fileListRef = node} style={{ maxHeight: fileListMaxHeight }}>
        {files.map((f, i) => (
          <li key={i}>
            <span>{f.file.name}</span>
            <span className="size">{bytes(f.file.size)}</span>
            {f.errorMessage && <span className="error">{f.errorMessage}</span>}
            {f.status === STATUS_PENDING ? (
              <a href="#remove" className="remove" onClick={e => { e.preventDefault(); this.handleRemoveFile(i); }} />
            ) : (
              <span className="status">
                {f.status === STATUS_UPLOADING && <LoadingSpinner size={24} />}
                {f.status === STATUS_COMPLETE && <Icon name="check_circle" className="text-success" />}
                {f.status === STATUS_FAILED && <Icon name="warning" className="text-error" />}
              </span>
            )}
          </li>
        ))}
      </ul>
    );
  }

  render () {
    const { uploading, uploadComplete, uploadError } = this.state;

    if (uploadError) {
      return (
        <div className="media-import-uploader">
          <div className="toast toast-error">Upload failed: {uploadError}</div>
        </div>
      );
    }

    return (
      <div className="media-import-uploader">
        {uploading && this.renderProgressBar()}
        {!uploading && !uploadComplete && this.renderFileInput()}
        {uploadComplete && this.renderCompleteMessage()}
        {this.renderFileList()}
        {!uploadComplete && this.renderActionBar()}
      </div>
    );
  }
}

FileUpload.propTypes = {
  csrfToken: PropTypes.string,
  instanceId: PropTypes.string,
  onComplete: PropTypes.func,
};

export default withCSRF(FileUpload);
