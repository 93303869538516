import React, { useState } from 'react';
import PropTypes from 'prop-types';
import snakeize from 'snakeize';
import { customTitleFieldTypes as ft } from 'app-constants';
import renderBlockErrors from './renderBlockErrors';
import Select from 'components/common/Select';
import TitleRelationField from 'components/views/CustomTitleBuilder/CustomTitleField/TitleRelationField';
import DisplayOptions, { TYPE_BLOCK, getDisplayOptionsConfig } from 'components/common/DisplayOptions';


const Title = ({
  uid,
  errors,
  title,
  size,
  displayOptions,
  onPreviewTextUpdate,
  onChange,
}) => {
  const [titleData, setTitleData] = useState(null);

  displayOptions = snakeize(displayOptions || {});
  const displayOptionsEmpty = !displayOptions || Object.keys(displayOptions).length === 0;

  const handleTitleChange = val => {
    onChange({
      title: val,
      displayOptions: {},
    });

    if (!val) {
      onPreviewTextUpdate('');
      setTitleData(null);
    }
  };

  const handlePreviewLoad = title => {
    onPreviewTextUpdate(`${title.name} (${title.type})`);
    setTitleData(title);
    if (displayOptionsEmpty) {
      const { defaultValues } = getDisplayOptionsConfig(TYPE_BLOCK, title.type);
      onChange({ displayOptions: defaultValues });
    }
  };

  const sizeOptions = [
    { value: 'small', label: 'Small' },
    { value: 'medium', label: 'Medium' },
    { value: 'large', label: 'Large' },
    { value: 'full', label: 'Full width' },
  ];
  const sizeSelectVal = sizeOptions.find(opt => opt.value === size);
  const handleSizeChange = val => onChange({ size: val });

  const displayOptionsConfig = (titleData && titleData.coverLayout === 'filmstrip') ? {
    fields: [
      {
        name: 'image_size',
        type: ft.choice,
        radio: true,
        choices: [
          ['small', 'Small'],
          ['medium', 'Medium'],
          ['large', 'Large'],
        ],
        label: 'Image size',
        defaultValue: 'medium',
      },
    ],
    menuItems: [],
  } : undefined;

  const handleDisplayOptionsChange = val => {
    onChange({ displayOptions: val });
  };

  const showDisplayOptions = !!titleData && !displayOptionsEmpty;

  const renderedErrors = renderBlockErrors(errors);

  return (
    <div className="content">
      <div className="mb-3">
        <TitleRelationField
          value={title}
          limitTypes={['Playlist', 'Pin Map', 'Contributors', 'Slideshow', 'Timeline', 'Cover']}
          onChange={handleTitleChange}
          onPreviewLoad={handlePreviewLoad}
        />
        {renderedErrors.title}
      </div>

      <div>
        <div>Size:</div>
        <Select
          value={sizeSelectVal}
          options={sizeOptions}
          onChange={handleSizeChange}
        />
        {renderedErrors.size}
      </div>

      {showDisplayOptions && (
        <div className="mt-4">
          <DisplayOptions
            config={displayOptionsConfig}
            parentType={TYPE_BLOCK}
            itemType={titleData.type}
            value={displayOptions}
            onChange={handleDisplayOptionsChange}
          />
        </div>
      )}
    </div>
  );
};

Title.propTypes = {
  uid: PropTypes.string,
  errors: PropTypes.object,
  title: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'full']),
  displayOptions: PropTypes.object,
  onPreviewTextUpdate: PropTypes.func,
  onChange: PropTypes.func,
};

export default Title;
